import { Component, Input, OnInit } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { PatientSelectors, ProfileSelectors } from '@app/core';
import { filterTruthy } from '@app/utils';

import { LastViewedByResponse } from '../../shared/pdmp.type';

export const pdmpLastViewedByQuery = gql`
  query GetPdmpLastViewedByInfo($id: ID!, $state: String!) {
    pdmpLastViewed(patientId: $id, requestedLocation: $state) {
      requestedLocation
      viewedAt
      viewedBy {
        displayName
      }
    }
  }
`;

interface LastViewedByObject {
  viewedBy: string;
  viewedAt: Date;
}

const mapToPdmpLastViewedObject = ({ data }): LastViewedByObject => {
  let viewedBy;
  let viewedAt;
  if (data.pdmpLastViewed) {
    viewedBy = data.pdmpLastViewed.viewedBy?.displayName;
    viewedAt = new Date(data.pdmpLastViewed.viewedAt);
  } else {
    viewedBy = null;
    viewedAt = new Date();
  }

  return { viewedBy, viewedAt };
};

@Component({
  selector: 'omg-pdmp-last-viewed-by-view',
  templateUrl: './last-viewed-by-view.component.html',
  styleUrls: ['./last-viewed-by-view.component.scss'],
})
export class PdmpLastViewedByViewComponent implements OnInit {
  @Input() state: string;

  primaryIdentityName$: Observable<string>;
  lastViewedByInfo$: Observable<LastViewedByObject>;

  constructor(
    private profile: ProfileSelectors,
    private apollo: Apollo,
    private patientSelectors: PatientSelectors,
  ) {}

  ngOnInit() {
    this.primaryIdentityName$ = this.profile.primaryIdentityName;
    this.lastViewedByInfo$ = this.patientSelectors.patientId.pipe(
      filterTruthy(),
      switchMap(patientId =>
        this.apollo.use('onelife').query<LastViewedByResponse>({
          query: pdmpLastViewedByQuery,
          variables: { id: patientId, state: this.state },
        }),
      ),
      map(mapToPdmpLastViewedObject),
    );
  }
}
