import { visit } from 'graphql';
import { Observable, Subject } from 'rxjs';
import { debounceTime, switchMap, take, takeUntil } from 'rxjs/operators';

import {
  VisitProcedure,
  VisitProcedureError,
} from '@app/features/service-ticket/shared/visit-procedure.type';
import { VisitProcedureActions } from '@app/features/service-ticket/store/visit-procedure.actions';
import { VisitProcedureSelectors } from '@app/features/service-ticket/store/visit-procedure.selectors';
import { DynamicFormGroup } from '@app/utils/forms/base';

export class ServiceTicketForm extends DynamicFormGroup {
  CONTROL_NAME_ID = 'id';
  CONTROL_NAME_VISIT_TYPE = 'visitProcedureTypeId';
  CONTROL_NAME_RULE = 'visitProcedureRuleId';
  CONTROL_NAME_PRIMARY_PROBLEM_CODE = 'primaryVisitProcedureProblemCodeId';
  CONTROL_NAME_PRIMARY_ASSESSED_PROBLEM = 'primaryAssessedProblemHistoryId';
  CONTROL_NAME_NEW_FINDINGS = 'newFindings';
  CONTROL_NAME_COUNSELING_THRESHOLD_MET = 'counselingThresholdMet';
  CONTROL_NAME_VIRTUAL = 'virtual';
  CONTROL_NAME_ADMINISTRATIVE_COMMENTS = 'administrativeComments';
  CONTROL_NAME_FIRST_VISIT = 'firstVisit';
  CONTROL_NAME_TIME_BASED = 'timeBased';
  CONTROL_NAME_TIME_BASED_MINUTES = 'timeBasedMinutes';

  errors: VisitProcedureError;

  private unsubscribe = new Subject<void>();
  private visitProcedure: VisitProcedure;

  constructor(
    private actions: VisitProcedureActions,
    private selectors: VisitProcedureSelectors,
  ) {
    super();
    this.addControls();
    this.initAutoSave();
  }

  initAutoSave() {
    this.controls.valueChanges
      .pipe(
        debounceTime(500),
        switchMap(formValues => this.update(formValues)),
        takeUntil(this.unsubscribe),
      )
      .subscribe();
  }

  update(changes): Observable<VisitProcedure> {
    const occupationalType = this.visitProcedure.visitProcedureTypes.find(
      t => t.systemName === 'occupational_medicine_workers_comp',
    );
    if (changes.visitProcedureTypeId !== occupationalType.id) {
      changes.firstVisit = !changes.firstVisit;
    }

    this.actions.update({ id: changes.id, changes });
    return this.selectors.getById(changes.id).pipe(take(1));
  }

  setVisitProcedure(visitProcedure: VisitProcedure) {
    this.visitProcedure = visitProcedure;

    let patch: { [key: string]: any } = {
      [this.CONTROL_NAME_ID]: visitProcedure.id,
      [this.CONTROL_NAME_VISIT_TYPE]: visitProcedure.visitProcedureType.id,
      [this.CONTROL_NAME_RULE]: visitProcedure.visitProcedureRuleId,
      [this
        .CONTROL_NAME_PRIMARY_PROBLEM_CODE]: visitProcedure.visitProcedureProblemCodes
        .filter(pc => pc.primary)
        .map(pc => pc.id)[0],
      [this.CONTROL_NAME_NEW_FINDINGS]: visitProcedure.newFindings,
      [this.CONTROL_NAME_COUNSELING_THRESHOLD_MET]:
        visitProcedure.counselingThresholdMet,
      [this.CONTROL_NAME_VIRTUAL]: visitProcedure.virtual,
      [this.CONTROL_NAME_TIME_BASED]: visitProcedure.timeBased,
      [this.CONTROL_NAME_FIRST_VISIT]:
        visitProcedure.visitProcedureType.systemName ===
        'occupational_medicine_workers_comp'
          ? visitProcedure.firstVisit
          : !visitProcedure.firstVisit,
    };

    if (visitProcedure.assessedProblems) {
      patch = {
        ...patch,
        [this.CONTROL_NAME_PRIMARY_ASSESSED_PROBLEM]:
          visitProcedure.primaryAssessedProblemHistoryId,
      };
    } else {
      patch = {
        ...patch,
        [this
          .CONTROL_NAME_PRIMARY_PROBLEM_CODE]: visitProcedure.visitProcedureProblemCodes
          .filter(pc => pc.primary)
          .map(pc => pc.id)[0],
      };
    }

    // Only populate this control once. After initializing it, we don't patch it
    // from updates to the visit procedure. This is to prevent characters getting
    // deleted because of latency between saving and typing more characters.
    if (!this.controls.get(this.CONTROL_NAME_ADMINISTRATIVE_COMMENTS).value) {
      patch = {
        ...patch,
        [this.CONTROL_NAME_ADMINISTRATIVE_COMMENTS]:
          visitProcedure.serviceTicket.administrativeComments,
      };
    }

    // Only populate this control once. Similar reasons above, this is to prevent
    // characters from being deleted becase of latency between saving and typing
    // more characters.
    if (!this.controls.get(this.CONTROL_NAME_TIME_BASED_MINUTES).value) {
      patch = {
        ...patch,
        [this.CONTROL_NAME_TIME_BASED_MINUTES]: visitProcedure.timeBasedMinutes,
      };
    }

    this.controls.patchValue(patch, { emitEvent: false });
  }

  toggleDisabled(disabled: boolean) {
    disabled
      ? this.controls.disable({ emitEvent: false })
      : this.controls.enable({ emitEvent: false });
  }

  dispose() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private addControls() {
    this.addControl({
      name: this.CONTROL_NAME_ID,
    });
    this.addControl({
      name: this.CONTROL_NAME_VISIT_TYPE,
    });
    this.addControl({
      name: this.CONTROL_NAME_RULE,
    });
    this.addControl({
      name: this.CONTROL_NAME_PRIMARY_PROBLEM_CODE,
    });
    this.addControl({
      name: this.CONTROL_NAME_PRIMARY_ASSESSED_PROBLEM,
    });
    this.addControl({
      name: this.CONTROL_NAME_NEW_FINDINGS,
    });
    this.addControl({
      name: this.CONTROL_NAME_COUNSELING_THRESHOLD_MET,
    });
    this.addControl({
      name: this.CONTROL_NAME_VIRTUAL,
    });
    this.addControl({
      name: this.CONTROL_NAME_ADMINISTRATIVE_COMMENTS,
    });
    this.addControl({
      name: this.CONTROL_NAME_FIRST_VISIT,
    });
    this.addControl({
      name: this.CONTROL_NAME_TIME_BASED,
    });
    this.addControl({
      name: this.CONTROL_NAME_TIME_BASED_MINUTES,
    });
  }
}
