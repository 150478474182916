import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  NgZone,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Dropdown } from 'primeng/dropdown';

import { DropdownItem, GroupedDropdownItem } from './dropdown-item';

@Component({
  selector: 'omg-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DropdownComponent),
    },
  ],
  // NOTE: OnPush causes some issues with the underlying PrimeNg component
  // - In particular the disabled state sometimes doesn't change until the
  //   next change dectection check.
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownComponent implements ControlValueAccessor, OnChanges {
  @Input()
  get value() {
    return this._value;
  }
  set value(value: any) {
    if (this._value !== value) {
      this._value = value;
      this.onChange(value);
    }
  }

  // This is a workaround to use the primeng dropdown in a flexbox
  get inlineStyle() {
    const inlineStyle = {} as CSSStyleDeclaration;

    if (this.flexValue) {
      inlineStyle.width = `${this.flexValue}%`;
    } else if (this.flex) {
      inlineStyle.width = '100%';
    }
    return inlineStyle;
  }

  constructor(private ngZone: NgZone) {}
  @Input() autoDisplayFirst = true;
  @Input() options: DropdownItem[] | GroupedDropdownItem[] | any[];
  @Input() optionLabel: string = null;
  @Input() optionKey: string = null;
  @Input() placeholder: string = null;
  @Input() filter: false;
  @Input() filterBy = 'label';
  @Input() filterPlaceholder: string = null;
  @Input() tabindex: number;
  @Input() disabled = false;
  @Input() readonly = false;
  @Input() required = false;
  @Input() group = false;
  @Input() flex = false;
  @Input() appendTo = 'body';
  @Input() label: string;

  @Output() dropdownFocus: EventEmitter<any> = new EventEmitter<any>();
  @Output() dropdownHidden: EventEmitter<any> = new EventEmitter<any>();
  @Output() dropdownShown: EventEmitter<any> = new EventEmitter<any>();
  @Output() dropdownUpdated: EventEmitter<any> = new EventEmitter<any>();

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('om-flex') flexValue: number = null;

  @ViewChild(Dropdown, { static: true }) private ref: Dropdown;
  private _value = false;

  calculatedReadOnly: boolean;
  styleClass = '';

  onChange = (value: any) => {};
  onTouched = () => {};

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.options &&
      changes.options.currentValue !== changes.options.previousValue
    ) {
      this.setCalculatedReadOnly();
    }
  }

  /* istanbul ignore next */
  onShow(event) {
    this.ngZone.runOutsideAngular(() =>
      window.addEventListener('scroll', this.closeDropdown.bind(this), true),
    );
    this.dropdownShown.emit();
  }

  /* istanbul ignore next */
  onHide(event) {
    this.ngZone.runOutsideAngular(() =>
      window.removeEventListener('scroll', this.closeDropdown.bind(this), true),
    );
    this.dropdownHidden.emit();
  }

  focus() {
    this.ref.focus();
  }

  onFocus() {
    this.dropdownFocus.emit();
  }

  onUpdate() {
    this.dropdownUpdated.emit();
  }

  /* istanbul ignore next */
  writeValue(value: any) {
    this._value = value;
  }

  /* istanbul ignore next */
  registerOnChange(fn: (value: any) => {}) {
    this.onChange = fn;
  }

  /* istanbul ignore next */
  registerOnTouched(fn: () => {}) {
    this.onTouched = fn;
  }

  /* istanbul ignore next */
  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  /* istanbul ignore next */
  private closeDropdown(event) {
    if (this.ref && this.ref.overlayVisible) {
      this.ref.overlayViewChild.alignOverlay();
    }
  }

  setCalculatedReadOnly() {
    this.calculatedReadOnly =
      this.readonly ||
      (!!this.options &&
        [0, 1].includes(this.options.length) &&
        this.autoDisplayFirst);
    if (this.calculatedReadOnly) {
      if (!this.styleClass.includes('ui-state-readonly')) {
        this.styleClass += ' ui-state-readonly';
      }
    } else {
      this.styleClass = this.styleClass.replace('ui-state-readonly', '');
    }
  }
}
