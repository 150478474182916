import { Injectable } from '@angular/core';
import { gql } from 'apollo-angular';
import { map } from 'rxjs/operators';

import { GraphQLService } from '@app/core/graphql/graphql.service';
import { TodoReassignmentResponse } from '@app/modules/messaging/shared/todo-reassignment-api.type';
import { mapResponseToTodoReassignment } from '@app/modules/messaging/shared/todo-reassignment-mappers';

export const todoReassignmentQuery = gql`
  query GetTodoMessageReassignment($postId: ID!, $patientId: ID!) {
    patients(ids: [$patientId]) {
      posts(ids: [$postId]) {
        nodes {
          reassignment: todoMessageReassignment {
            id
            newAssignee {
              displayName
            }
            originalAssignee {
              displayName
            }
            labels
            feedback {
              id
              newAssignee {
                displayName
              }
            }
          }
        }
      }
    }
  }
`;

export interface TodoReassignment {
  id: string;
  newAssigneeName: string;
  originalAssigneeName: string;
  labels: string[];
  feedbackExists: boolean;
  feedbackHasNewAssignee: boolean;
}

@Injectable()
export class TodoReassignmentService {
  constructor(private graphQL: GraphQLService) {}

  getTodoMessageReassignment(postId: number, patientId: number) {
    return this.graphQL
      .query<TodoReassignmentResponse>(todoReassignmentQuery, {
        postId,
        patientId,
      })
      .pipe(map(mapResponseToTodoReassignment));
  }
}
