import { ValidatorFn, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import {
  debounceTime,
  filter,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';

import { ProcedureOrder } from '@app/features/procedure-order/shared/procedure-order.type';
import { ProcedureOrderActions } from '@app/features/procedure-order/store/procedure-order.actions';
import { ProcedureOrderSelectors } from '@app/features/procedure-order/store/procedure-order.selectors';
import { FormModel } from '@app/shared';
import { DynamicFormGroup } from '@app/utils/forms/base';

export const contactIdControlName = 'contactId';
export class ProcedureSuggestionProcedureOrderForm extends DynamicFormGroup {
  model: FormModel;
  private unsubscribe = new Subject<void>();

  constructor(
    private actions: ProcedureOrderActions,
    private selectors: ProcedureOrderSelectors,
  ) {
    super();

    this.buildForm();
  }

  private buildForm() {
    this.addControls();
    this.initAutoSave();
  }

  initAutoSave() {
    this.controls.valueChanges
      .pipe(
        debounceTime(500),
        switchMap(formValues => this.update(formValues)),
        takeUntil(this.unsubscribe),
      )
      .subscribe();
  }

  update(changes: Partial<ProcedureOrder>): Observable<ProcedureOrder> {
    this.actions.update({
      id: changes.id,
      changes,
    });
    return this.selectors.getById(changes.id);
  }

  setProcedureOrder(procedureOrder: ProcedureOrder) {
    let patch: { [key: string]: any } = {
      id: procedureOrder.id,
      status: procedureOrder.status,
    };

    if (!this.controls.get('results').value) {
      patch = {
        ...patch,
        results: procedureOrder.results,
      };
    }

    this.controls.patchValue(patch, { emitEvent: false });
  }

  private addControls() {
    const controls: {
      name: string;
      defaultValue?: any;
      validators?: ValidatorFn[];
    }[] = [
      { name: 'id' },
      { name: 'results', validators: [Validators.required] },
      { name: 'status', validators: [Validators.required] },
    ];

    controls.forEach(control =>
      this.addControl({
        name: control.name,
        validators: control.validators,
      }),
    );
  }
}
