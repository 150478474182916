import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';

import { CollapseDirective } from '../collapse';

@Component({
  selector: 'omg-card-title',
  templateUrl: './card-title.component.html',
  styleUrls: ['./card-title.component.scss'],
})
export class CardTitleComponent {
  @Input() heading: string;
  @Input() smallFontSize = false;
  @Input() isExpandable = false;
  @Input() collapseProvider: CollapseDirective;
  @Input() badgeCount$: Observable<number>;

  @ViewChild('cardTitle', { static: true }) cardTitle: ElementRef;
}
