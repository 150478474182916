<div class="narx-scores-container">
  <div class="row">
    <div class="column">
      <table class="narx-table">
        <tr>
          <th colspan="3"
              class="narx-th">Narx Scores
            <omg-popover placement="right"
                         [enabled]="true"
                         withFlexibleDimensions="true"
                         #narxScoresExplanationPopover>
              <omg-pdmp-narx-scores-explanation></omg-pdmp-narx-scores-explanation>
            </omg-popover>

            <span [omgPopoverTriggerFor]="narxScoresExplanationPopover"
                  triggerOn="hover">
              <span class="om-icon -small icon-alert-info"></span>
            </span>
          </th>
        </tr>
        <tr>
          <td>
            <div class="narx-type">Narcotic</div>
            <div *ngIf="narxScores?.length; else emptyNarxValue"
                 class="narx-value">{{narxScoresForDisplay.narcotic}}</div>
          </td>
          <td>
            <div class="narx-type">Sedative</div>
            <div *ngIf="narxScores?.length; else notAvailableNarxValue"
                 class="narx-value">{{narxScoresForDisplay.sedative}}</div>
          </td>
          <td>
            <div class="narx-type">Stimulant</div>
            <div *ngIf="narxScores?.length; else emptyNarxValue"
                 class="narx-value">{{narxScoresForDisplay.stimulant}}</div>
          </td>
        </tr>
      </table>
    </div>
    <div class="column">
      <table class="overdose-table">
        <tr>
          <th class="narx-th">Overdose Score
            <omg-popover placement="right"
                         [enabled]="true"
                         #overdoseScoreExplanationPopover>
              <omg-pdmp-overdose-score-explanation></omg-pdmp-overdose-score-explanation>
            </omg-popover>

            <span [omgPopoverTriggerFor]="overdoseScoreExplanationPopover"
                  triggerOn="hover">
              <span class="om-icon -small icon-alert-info"></span>
            </span>
          </th>
        </tr>
        <tr>
          <td *ngIf="narxScores?.length; else notAvailableOverdoseValue"
              class="overdose-value">{{narxScoresForDisplay.overdose}}</td>
        </tr>
      </table>
    </div>
  </div>
</div>

<ng-template #emptyNarxValue>
  <div>&nbsp;</div>
</ng-template>

<ng-template #notAvailableNarxValue>
  <div class="not-available-value">Not available</div>
</ng-template>

<ng-template #notAvailableOverdoseValue>
  <td class="not-available-value">Not available</td>
</ng-template>
