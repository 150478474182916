import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { combineLatest, empty, Observable, of, throwError } from 'rxjs';
import {
  catchError,
  map,
  shareReplay,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { PatientSelectors } from '@app/core';
import { TimelineActions } from '@app/features/timeline/store/timeline.actions';
import {
  messagingPath,
  summariesPath,
} from '@app/features/workspace/shared/workspace-utils';
import {
  TodoReassignment,
  TodoReassignmentService,
} from '@app/modules/messaging/shared/todo-reassignment.service';
import { Todo } from '@app/modules/todo/shared/todo.type';
import { TodoSelectors } from '@app/modules/todo/store/todo.selectors';
import { FormModel } from '@app/shared';
import { ToastMessageService } from '@app/shared/components/toast';
import { filterTruthy } from '@app/utils';
import { cloneDeep } from '@app/utils/shared/lodash-fp';

import {
  canAutosaveMessage,
  isMessage,
  isPost,
} from '../../shared/messaging-utils';
import {
  MessagingError,
  MessagingErrorResponse,
  MessagingService,
} from '../../shared/messaging.service';
import {
  Message,
  Post,
  PostContentAttributes,
} from '../../shared/messaging.type';

@Component({
  selector: 'omg-messaging-container',
  templateUrl: './messaging-container.component.html',
  styleUrls: ['./messaging-container.component.scss'],
})
export class MessagingContainerComponent implements OnInit, OnChanges {
  @Input() postId: number;
  @Input() docked = false;
  @Output() closeMessagingContainer = new EventEmitter();
  @Output() threadDeleted = new EventEmitter();

  isMinimized = false;
  post: Observable<Post>;
  todo: Observable<Todo>;
  todoReassignment: TodoReassignment;
  patientWarnings: Observable<string>;
  postFormModel: FormModel;
  inProgressMessage: Message | Post;
  fullPost: Post;

  sendingMessage: boolean;

  constructor(
    private messagingService: MessagingService,
    private todoSelectors: TodoSelectors,
    private patientSelectors: PatientSelectors,
    private router: Router,
    private toastService: ToastMessageService,
    private timelineActions: TimelineActions,
    private todoReassignmentService: TodoReassignmentService,
  ) {}

  ngOnInit() {
    this.setupSelectors();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.postId) {
      if (
        !changes.postId.firstChange &&
        changes.postId.previousValue !== changes.postId.currentValue
      ) {
        this.setupSelectors();
      }
    }
  }

  getMessageInProgress(post: Post): Message | Post {
    if (!post.patientVisible) {
      return null;
    }

    if (post.draft) {
      return post;
    }

    const draftComment = post.messages.find(message => message.draft);
    if (draftComment) {
      return draftComment;
    }

    return {
      draft: true,
      html: '',
      s3Pointers: [],
      notificationRecipient: null,
      replyTo: null,
    } as Message;
  }

  setupForm(post: Post, inProgressMessage: Post | Message): FormModel {
    const formGroup = new UntypedFormGroup({
      topic: new UntypedFormControl(post.contentAttributes.topic),
      assignRepliesTo: new UntypedFormControl(
        inProgressMessage && inProgressMessage.replyTo,
      ),
      notify: new UntypedFormControl(
        inProgressMessage && inProgressMessage.notificationRecipient,
      ),
      s3Pointers: new UntypedFormControl(
        inProgressMessage && inProgressMessage.s3Pointers,
      ),
      html: new UntypedFormControl(
        inProgressMessage &&
          (isPost(inProgressMessage)
            ? (<Post>inProgressMessage).contentAttributes.html
            : (<Message>inProgressMessage).html),
      ),
    });
    return new FormModel(formGroup, {
      autosaveDelay: 1000,
      saveFunction: this.autosave.bind(this),
      savePredicate: canAutosaveMessage,
      mapSaveError: error => {
        if (error && error.errors) {
          return Object.values(error.errors);
        }
      },
    });
  }

  onDeleteDraft(post: Post, inProgressMessage: Message | Post) {
    if (post.draft) {
      this.messagingService
        .deletePost(post.id)
        .pipe(take(1), withLatestFrom(this.patientSelectors.patientId))
        .subscribe(([resp, patientId]) => {
          this.threadDeleted.emit();
          this.timelineActions.refreshTimeline();
          this.router.navigateByUrl(summariesPath(patientId, null, 'new'));
        });
    } else if (inProgressMessage.id) {
      this.messagingService
        .deletePostMessage(inProgressMessage, post.id)
        .pipe(
          take(1),
          catchError(err => this.updateMessageFailure(err)),
        )
        .subscribe(resp => {
          this.timelineActions.refreshTimeline();

          post.messages = post.messages.filter(
            msg => msg.id !== inProgressMessage.id,
          );
          this.fullPost = post;
          this.inProgressMessage = this.getMessageInProgress(post);
          this.postFormModel.reset();
        });
    }
  }

  onClose() {
    this.closeMessagingContainer.emit();
  }

  onSend(event) {
    this.sendingMessage = true;

    const toSave = {
      ...this.inProgressMessage,
      notificationRecipient: this.postFormModel.value.notify,
      s3Pointers: this.postFormModel.value.s3Pointers,
      replyTo: this.postFormModel.value.assignRepliesTo,
      draft: false,
      event,
    };

    if (isPost(this.inProgressMessage)) {
      if (!(<Post>toSave).contentAttributes) {
        (<Post>toSave).contentAttributes = {} as PostContentAttributes;
      }

      (<Post>toSave).contentAttributes.html =
        this.postFormModel.value.html || '';
      (<Post>toSave).contentAttributes.topic =
        this.postFormModel.value.topic || '';

      this.sendPost(toSave as Post);
    } else {
      (<Message>toSave).html = this.postFormModel.value.html;
      this.sendMessage(toSave as Message);
    }
  }

  minimizeChange(isMinimized) {
    this.isMinimized = isMinimized;
  }

  private postOrMessageSent(response: Post | Message) {
    this.sendingMessage = false;
    this.toastService.add({
      severity: 'success',
      detail: 'Your message was sent',
    });
    this.timelineActions.refreshTimeline();

    if (this.docked) {
      this.onClose();
    } else {
      this.setupSelectors();
    }
  }

  private sendPost(post: Post) {
    if (post.id) {
      this.messagingService
        .updatePost(post)
        .pipe(take(1))
        .subscribe(this.postOrMessageSent.bind(this));
    } else {
      this.messagingService
        .savePost(post)
        .pipe(take(1))
        .subscribe(this.postOrMessageSent.bind(this));
    }
  }

  private sendMessage(message: Message) {
    if (message.id) {
      this.messagingService
        .updatePostMessage(message, this.postId)
        .pipe(
          take(1),
          catchError(err => this.updateMessageFailure(err)),
        )
        .subscribe(this.postOrMessageSent.bind(this));
    } else {
      this.messagingService
        .createPostMessage(message, this.postId)
        .pipe(take(1))
        .subscribe(this.postOrMessageSent.bind(this));
    }
  }

  private setupSelectors() {
    this.post = combineLatest([
      this.messagingService.getPostWithTodo(this.postId),
      this.patientSelectors.patientId.pipe(
        switchMap(patientId =>
          this.todoReassignmentService.getTodoMessageReassignment(
            this.postId,
            patientId,
          ),
        ),
      ),
    ]).pipe(
      tap(([post, todoReassignment]: [Post, TodoReassignment]) => {
        this.fullPost = cloneDeep(post);
        this.todo = this.todoSelectors.todoById(post.todoId);
        this.todoReassignment = todoReassignment;
        this.inProgressMessage = this.getMessageInProgress(post);
        this.postFormModel = this.setupForm(post, this.inProgressMessage);
      }),
      map(([post]: [Post, TodoReassignment]) => post),
      shareReplay(1),
    );

    this.patientWarnings = this.patientSelectors.patientWarnings.pipe(
      filterTruthy(),
      map(warnings => Object.values(warnings).join(' ')),
    );
  }

  private mergeS3Pointers(data: Post | Message, response: Post | Message) {
    const newPointers = [];
    if (!data.s3Pointers) {
      data.s3Pointers = response.s3Pointers;
      return;
    }

    for (const existingPointer of data.s3Pointers) {
      const pointer = response.s3Pointers.find(
        ptr =>
          ptr.bucket === existingPointer.bucket &&
          ptr.key === existingPointer.key,
      );
      if (pointer) {
        newPointers.push(
          Object.assign({}, existingPointer, { id: pointer.id }),
        );
      } else if (!existingPointer.destroy) {
        newPointers.push(existingPointer);
      }
    }
    data.s3Pointers = newPointers;
    this.postFormModel.patchValue({ s3Pointers: newPointers });
  }

  private mergePostResponse(response: Post | Message) {
    if (response.draft !== this.inProgressMessage.draft) {
      this.toastService.add({
        severity: 'warn',
        detail: 'This thread has already been sent. Reloading the thread...',
      });
      this.setupSelectors();
      this.timelineActions.refreshTimeline();
      return;
    }

    this.mergeS3Pointers(this.inProgressMessage, response);
  }

  private replacePost(oldPost: Post) {
    this.messagingService
      .clonePost(oldPost)
      .pipe(take(1), withLatestFrom(this.patientSelectors.patientId))
      .subscribe(([post, patientId]) => {
        this.timelineActions.refreshTimeline();
        this.router.navigateByUrl(messagingPath(patientId, post.id, 'edit'));
      });
  }

  private updatePostFailure(
    err: MessagingErrorResponse,
    post: Post,
  ): Observable<any> {
    if (err.kind === MessagingError.PostNotFound) {
      this.toastService.add({
        severity: 'warn',
        detail:
          'The thread has been deleted. Creating a new thread with your changes...',
      });
      this.replacePost(post);
      return empty();
    }

    return throwError(err);
  }

  private autosavePost(formValues): Observable<boolean> {
    const post = <Post>this.inProgressMessage;

    if (!post.contentAttributes) {
      post.contentAttributes = {} as PostContentAttributes;
    }

    post.contentAttributes.html = formValues.html || '';
    post.contentAttributes.topic = formValues.topic || '';
    post.s3Pointers = formValues.s3Pointers || [];

    const topicChanged =
      this.fullPost.contentAttributes.topic !== formValues.topic;

    return this.messagingService.updatePost(post).pipe(
      catchError(err => this.updatePostFailure(err, post)),
      tap(response => this.mergePostResponse(response)),
      tap(() => {
        if (topicChanged) {
          this.fullPost.contentAttributes.topic = formValues.topic;
          this.timelineActions.refreshTimeline();
        }
      }),
      map(response => !!response),
    );
  }

  private replaceMessage(): Observable<any> {
    this.timelineActions.refreshTimeline();
    this.fullPost.messages = this.fullPost.messages.filter(
      message => message.id !== this.inProgressMessage.id,
    );
    delete this.inProgressMessage.id;
    this.inProgressMessage.s3Pointers.forEach(pointer => {
      delete pointer.id;
    });
    return this.postFormModel.save();
  }

  private updateMessageFailure(err: MessagingErrorResponse): Observable<any> {
    if (err.kind === MessagingError.MessageNotFound) {
      this.toastService.add({
        severity: 'warn',
        detail:
          'This reply has been deleted. A new draft has been created with your changes.',
      });
      return this.replaceMessage();
    }

    if (
      err.kind === MessagingError.MessageInvalid &&
      err.errors &&
      err.errors.draft
    ) {
      this.toastService.add({
        severity: 'warn',
        detail: 'This reply has already been sent. Reloading the thread...',
      });
      this.setupSelectors();
      this.timelineActions.refreshTimeline();
      return empty();
    }

    return throwError(err);
  }

  private autosaveMessage(formValues): Observable<boolean> {
    const msg = <Message>this.inProgressMessage;
    msg.html = formValues.html;
    msg.s3Pointers = formValues.s3Pointers;

    if (this.inProgressMessage.id) {
      return this.messagingService.updatePostMessage(msg, this.postId).pipe(
        catchError(err => this.updateMessageFailure(err)),
        tap(response => this.mergeS3Pointers(this.inProgressMessage, response)),
        map(response => !!response),
      );
    }
    return this.messagingService.createPostMessage(msg, this.postId).pipe(
      map(response => {
        this.fullPost.messages.push(response);
        this.inProgressMessage = this.getMessageInProgress(this.fullPost);
        this.timelineActions.refreshTimeline();
        this.mergeS3Pointers(this.inProgressMessage, response);

        return !!response;
      }),
    );
  }

  private autosaveTopic(topic: string): Observable<boolean> {
    return this.messagingService
      .updatePost({
        ...this.fullPost,
        contentAttributes: {
          ...this.fullPost.contentAttributes,
          topic,
        },
      })
      .pipe(
        tap(() => this.timelineActions.refreshTimeline()),
        map(response => {
          this.fullPost.contentAttributes.topic = topic;
          return !!response;
        }),
      );
  }

  private autosave(formValues): Observable<boolean> {
    if (this.sendingMessage === true) {
      return of(true);
    }

    if (isPost(this.inProgressMessage)) {
      return this.autosavePost(formValues);
    } else if (
      formValues.topic &&
      this.fullPost.contentAttributes.topic !== formValues.topic
    ) {
      return this.autosaveTopic(formValues.topic);
    } else if (isMessage(this.inProgressMessage)) {
      return this.autosaveMessage(formValues);
    }

    return of(true);
  }
}
