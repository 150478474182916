import { Component, Input, OnInit } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { combineLatest, Observable, ReplaySubject } from 'rxjs';
import { map, shareReplay, switchMap, tap } from 'rxjs/operators';

import { PatientSelectors } from '@app/core';
import { filterTruthy } from '@app/utils';

import {
  PdmpInfo,
  PdmpPatientAndNonParticipatingStatesResponse,
  PdmpStateInfo,
  RequestPdmpReport,
  RequestPdmpReportMutationResponse,
} from '../../../pdmp/shared/pdmp.type';

export const pdmpTabContentsQuery = gql`
  query GetDefaultReportStateInfo($id: ID!) {
    patient(id: $id) {
      id
      defaultPdmpReportState
    }
    pdmpNonParticipatingStates {
      id
      shortName
    }
  }
`;

export const requestPdmpReportMutation = gql`
  mutation RequestPdmpReport($id: ID!, $rxCartId: ID) {
    requestPdmpReport(input: { patientId: $id, rxCartId: $rxCartId }) {
      success
      reports {
        requestedLocation
        pmpDestinations
        reportUrl
        narxScores {
          type
          value
        }
        correlationId
        errors
      }
    }
  }
`;

const mapToPdmpStateInfo = ({
  data: { pdmpNonParticipatingStates, patient },
}) => {
  const defaultPdmpReportState = patient.defaultPdmpReportState;
  const hasParticipatingState = !pdmpNonParticipatingStates.find(
    state => state.shortName === defaultPdmpReportState,
  );

  return {
    pdmpNonParticipatingStates,
    defaultPdmpReportState,
    hasParticipatingState,
  };
};

const mapToPdmpReportInfo = ({
  data: {
    requestPdmpReport: { success, reports },
  },
}): RequestPdmpReport => {
  return { success, reports };
};

@Component({
  selector: 'omg-pdmp-tab-contents',
  templateUrl: './pdmp-tab-contents.component.html',
  styleUrls: ['./pdmp-tab-contents.component.scss'],
})
export class PdmpTabContentsComponent implements OnInit {
  @Input() rxCartId?: number;
  pdmpStateInfo$: Observable<PdmpStateInfo>;
  triggerReportRequest$ = new ReplaySubject<void>();
  pdmpInfo$: Observable<PdmpInfo>;
  isLoading: boolean = true;

  constructor(
    private apollo: Apollo,
    private patientSelectors: PatientSelectors,
  ) {}

  ngOnInit() {
    this.pdmpStateInfo$ = this.patientSelectors.patientId.pipe(
      filterTruthy(),
      switchMap(patientId =>
        this.apollo
          .use('onelife')
          .query<PdmpPatientAndNonParticipatingStatesResponse>({
            query: pdmpTabContentsQuery,
            variables: { id: patientId },
          }),
      ),
      map(mapToPdmpStateInfo),
    );

    const pdmpReportInfo$ = this.triggerReportRequest$.pipe(
      tap(() => (this.isLoading = true)),
      switchMap(() =>
        this.patientSelectors.patientId.pipe(
          filterTruthy(),
          switchMap(patientId => {
            return this.apollo
              .use('onelife')
              .mutate<RequestPdmpReportMutationResponse>({
                mutation: requestPdmpReportMutation,
                variables: { id: patientId, rxCartId: this.rxCartId },
              });
          }),
          map(mapToPdmpReportInfo),
          shareReplay(1),
        ),
      ),
      tap(() => (this.isLoading = false)),
    );

    this.pdmpInfo$ = combineLatest([this.pdmpStateInfo$, pdmpReportInfo$]).pipe(
      map(([pdmpStateInfo, pdmpReportInfo]) => {
        const reportsWithWhetherStateIsParticipating = pdmpReportInfo.reports?.map(
          report => {
            const stateIsParticipating = !pdmpStateInfo.pdmpNonParticipatingStates.some(
              state => state.shortName === report.requestedLocation,
            );
            return {
              ...report,
              stateIsParticipating,
              headingText: `Report for ${report.requestedLocation}`,
            };
          },
        );

        return {
          success: pdmpReportInfo.success,
          reports: reportsWithWhetherStateIsParticipating,
        };
      }),
    );

    this.triggerReportRequest$.next();
  }

  onReloadReportClick(): void {
    this.triggerReportRequest$.next();
  }
}
