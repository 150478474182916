import * as Rollbar from 'rollbar';
import { Injectable, Optional, Inject } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { Client } from 'elasticsearch-browser';
import { Observable, ReplaySubject } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';

import { CookieService } from '@app/core/auth/shared/cookie.service';
import { ConfigService } from '@app/core/config';
import { FeatureFlagNames } from '@app/core/feature-flag/shared/feature-flag.type';
import { LaunchDarklyService } from '@app/core/launch-darkly/launchdarkly.service';
import { ElasticsearchLocationService } from '@app/core/search/elasticsearch-location.service';
import { ElasticsearchLocation } from '@app/core/search/elasticsearch-location.type';
import { camelCase } from '@app/utils';
import { RollbarService } from '@app/core/errors/rollbar';


interface SearchOptions {
  camelize?: boolean;
}

@Injectable()
export class SearchService {
  private client$ = new ReplaySubject<Client>(1);
  private initialized = false;

  elasticsearchLocation: ElasticsearchLocation;

  constructor(
    private configService: ConfigService,
    private elasticsearchLocationService: ElasticsearchLocationService,
    private cookie: CookieService,
    private auth: AuthService,
    private launchDarklyService: LaunchDarklyService,
    @Optional() @Inject(RollbarService) private rollbar: Rollbar
  ) { }

  search(request: any, options: SearchOptions = {}): Observable<any> {
    this.initialize();
    return this.searchRequest(request, options, 'search');
  }

  scroll(request: any, options: SearchOptions = {}): Observable<any> {
    this.initialize();
    return this.searchRequest(request, options, 'scroll');
  }

  private searchRequest(
    request: any,
    options: SearchOptions = {},
    mode: 'search' | 'scroll',
  ) {
    return this.client$.pipe(
      switchMap(client => {
        if (!client) {
          throw new Error('Elasticsearch location is undefined');
        }
        const req =
          mode === 'scroll' ? client.scroll(request) : client.search(request);

        return req.then(response => {
          this.inspectForResponseFailures(response);
          return options.camelize ? camelCase(response) : response
        });
      }),
    );
  }

  private inspectForResponseFailures(response: any): void {
    // inspect ES client response and log if there are any failed shards
    // https://opensearch.org/docs/1.3/api-reference/search/#response-body
    // https://www.elastic.co/guide/en/elasticsearch/reference/current/docs-index_.html#docs-index-api-response-body
    // we don't check for `response._shards.skipped` because the default behaviour is to fail if any shards in a cluster fail.
    // shards are only skipped if the query explicitly sets `skip_unavailable` = true
    const failed = response?._shards?.failed > 0;
    if (failed) {
      this.rollbar.error('OpenSearch Result contains Failures', response?._shards?.failures);
    }
  }

  private initialize() {
    if (this.initialized) {
      return;
    }
    this.initialized = true;
    const useAuth0JwtAuth = this.launchDarklyService.variation(
      FeatureFlagNames.useAuth0JwtAuthForSearch,
      false,
    );

    if (useAuth0JwtAuth) {
      this.setupSearchClient();
    } else {
      return this.elasticsearchLocationService
        .get()
        .subscribe((esLocation: ElasticsearchLocation) => {
          if (!esLocation || !esLocation.url) {
            this.client$.next(null);
            return;
          }

          const esUrl = new URL(esLocation.url);
          this.setupLegacyElasticSearchClient(esUrl);
        });
    }
  }

  private setupSearchClient() {
    this.auth
      .getAccessTokenSilently()
      .pipe(
        take(1),
        tap((accessTokenValue: string) => {
          this.useAuth0JwtAccess(accessTokenValue);
        }),
      )
      .subscribe();
  }

  private useAuth0JwtAccess(accessTokenValue) {
    const headers = {
      'X-Access-Token': accessTokenValue,
      Authorization: `Bearer ${accessTokenValue}`,
    };

    const esUrl = new URL(this.configService.environment.search.proxyHost);
    const port = esUrl.port || '443';

    const elasticsearchClient = new Client({
      host: {
        host: esUrl.hostname,
        protocol: esUrl.protocol,
        port: port,
        path: esUrl.pathname,
        headers,
      },
    });
    this.client$.next(elasticsearchClient);
  }

  private setupLegacyElasticSearchClient(esUrl) {
    this.auth
      .getAccessTokenSilently()
      .pipe(
        take(1),
        tap((accessTokenValue: string) => {
          if (!accessTokenValue) {
            this.client$.next(new Client({ host: esUrl.href }));
            return;
          }

          const headers = { 'X-Access-Token': accessTokenValue };

          const basicCredentialsSupplied =
            esUrl.username.length !== 0 && esUrl.password.length !== 0;
          if (basicCredentialsSupplied) {
            this.useBasicCredentialsAccess(esUrl, headers);
          } else {
            // This block refers to the previously used Doorkeeper tokens
            // that used to power auth for 1Life UI and can be removed
            // when we have fully implemented Auth0. We're doing our due
            // dilligence here by adding the JWT to this call as well
            this.useDoorkeeperTokenAccess(esUrl, headers);
          }
        }),
      )
      .subscribe();
  }

  private useDoorkeeperTokenAccess(esUrl, headers) {
    const doorKeeperToken = this.cookie.get();
    if (doorKeeperToken) {
      headers = Object.assign(headers, {
        Authorization: `Bearer ${doorKeeperToken}`,
      });
    }

    const elasticsearchClient = new Client({
      host: {
        host: esUrl.hostname,
        protocol: esUrl.protocol,
        port: esUrl.port,
        path: esUrl.pathname,
        headers,
      },
    });
    this.client$.next(elasticsearchClient);
  }

  private useBasicCredentialsAccess(esUrl, headers) {
    const port = esUrl.port ? esUrl.port : '443';
    const elasticsearchClient = new Client({
      host: {
        href: esUrl.href,
        hostname: esUrl.hostname,
        protocol: esUrl.protocol,
        port,
        auth: decodeURI(`${esUrl.username}:${esUrl.password}`),
        headers,
      },
    });

    this.client$.next(elasticsearchClient);
  }
}
