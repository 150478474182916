import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { QuillModules } from 'ngx-quill';

import { TrackEventProperties } from '@app/core/analytics/analytics.type';
import { ConfigService } from '@app/core/config';
import { SearchIndex } from '@app/core/search';

import { InlineInsertionContainerComponent } from '../inline-insertion/inline-insertion-container.component';
import {
  InsertionTriggerConfiguration,
  QuillContentChangedEvent,
} from '../inline-insertion/inline-insertion.utils';
import { ScrollingContainerDirective } from './scrolling-container.directive';

@Component({
  selector: 'omg-chart-text-box',
  templateUrl: './chart-text-box.component.html',
  styleUrls: ['./chart-text-box.component.scss'],
})
export class ChartTextBoxComponent implements OnInit {
  @Input() control: UntypedFormControl;
  @Input() placeholderText = '';
  @Input() quillFormat = 'text';
  @Input() templateType = 'text';

  /**
   * Analytics properties passed to InlineInsertionDirective to provide context
   * about the component in which snippet insertion events occur.
   */
  @Input()
  insertionEventProps: Partial<TrackEventProperties>;

  @Input() scrollingContainer: HTMLElement | string;
  @Input() dataCy: string;
  @Input() singleLine = false;
  @Input() maxLength: number = null;

  @Output() init = new EventEmitter<any>();
  @Output() focusTextBox = new EventEmitter();
  @Output() focusoutTextbox = new EventEmitter();
  @Output() blurTextBox = new EventEmitter();
  @Output() enterPressed = new EventEmitter();
  @Output() textChange = new EventEmitter<any>();

  @ViewChild(InlineInsertionContainerComponent, { static: true })
  private ref: InlineInsertionContainerComponent;

  insertionTriggers: InsertionTriggerConfiguration;
  quillModules: QuillModules;
  quillFormats: string[];
  classes: string;

  isInsertionActive: boolean;

  constructor(
    private config: ConfigService,
    @Optional()
    private scrollingContainerDirective: ScrollingContainerDirective,
  ) {
    this.scrollingContainer = this.scrollingContainerDirective
      ? this.scrollingContainerDirective.nativeElement
      : '.om-section-left';
  }

  ngOnInit(): void {
    this.insertionTriggers = {
      '/':
        this.templateType === 'message'
          ? <SearchIndex>this.config.searchIndex('message_templates')
          : <SearchIndex>this.config.searchIndex('text_templates'),
    };
    this.quillFormats =
      this.quillFormat === 'html' ? ['bold', 'italic', 'link', 'list'] : [];
    this.classes = this.quillFormat === 'html' ? 'marked' : '';
    this.setQuillModules(false);
  }

  setQuillModules(isInsertionActive: boolean) {
    const toolbar: any =
      this.quillFormat === 'html'
        ? ['bold', 'italic', 'link', { list: 'ordered' }, { list: 'bullet' }]
        : null;
    this.isInsertionActive = isInsertionActive;
    this.quillModules = {
      toolbar: toolbar,
      keyboard: {
        // Do nothing and allow default browser functionality when menu is not open.
        // Disable keys when menu is open.
        bindings: {
          tab: {
            key: 9,
            handler: () => !this.isInsertionActive,
          },
          shiftTab: {
            key: 9,
            shiftKey: true,
            handler: () => !this.isInsertionActive,
          },
          arrowUp: {
            key: 38,
            handler: () => !this.isInsertionActive,
          },
          arrowDown: {
            key: 40,
            handler: () => !this.isInsertionActive,
          },
          enter: {
            key: 13,
            handler: this.enterKeyHandler,
          },
          shiftEnter: {
            key: 13,
            shiftKey: true,
            handler: this.enterKeyHandler,
          },
        },
      },
    };
  }

  contentChanged(event: QuillContentChangedEvent) {
    this.onTextChange(event.delta, event.source, event.html, event.text);
    this.checkMaxLength(event.editor);
  }

  onTextChange(
    delta: string,
    source: string,
    html: string,
    text: string,
  ): void {
    if (source === 'user') {
      const trimmedText = text.trim();
      const nonEmptyHtml = html === '<p><br></p>' ? null : html;

      this.textChange.emit({
        htmlValue: nonEmptyHtml,
        textValue: trimmedText,
        delta: delta,
        source: source,
      });
    }
  }

  checkMaxLength(quillEditor: any) {
    // TODO: Replace with `trimOnValidation` input once Angular 9 upgrade is complete and QuillJS version can be bumped.
    if (this.maxLength && quillEditor.getLength() > this.maxLength) {
      quillEditor.deleteText(
        this.maxLength - 1,
        quillEditor.getLength() - this.maxLength,
      );
      this.control.setValue(quillEditor.getText(), {
        emitModelToViewChange: false,
        emitViewToModelChange: false,
      });
    }
  }

  focus() {
    this.ref?.focus();
  }

  private enterKeyHandler = () => {
    if (this.singleLine && !this.isInsertionActive) {
      this.enterPressed.emit();
    }
    return !this.singleLine && !this.isInsertionActive;
  };
}
