import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { gql } from 'apollo-angular';
import { DocumentNode, print } from 'graphql';
import { Observable } from 'rxjs';

import { CookieService } from '../auth/shared/cookie.service';
import { DetailedError } from '../errors/detailed-errors.type';
import { mapGraphQLResponse } from './graphql.utils';

export interface GraphQLResponse<T> {
  data?: T;
  errors?: any[];
  detailedErrors?: DetailedError[];
}

/**
 * @deprecated Moving forward new calls to graphql endpoints should use the apollo client.  See https://github.com/onemedical/onelife-ui/pull/5268
 */
@Injectable()
export class GraphQLService {
  constructor(private http: HttpClient, private cookieService: CookieService) {}

  query<T>(query: DocumentNode, queryVariables?: Object): Observable<T> {
    const queryString = print(query);
    const endpoint = `${environment.api.baseUrl}/graphql`;
    const headers = this.getHeaders();
    const body = {
      query: queryString,
      variables: queryVariables,
    };
    return this.http
      .post<GraphQLResponse<T>>(endpoint, body, { headers })
      .pipe(mapGraphQLResponse<T>());
  }

  // This is used for validating auth0 permissions we need the credentials because the JWT is store on the session
  // When we move to having the JWT passed in as the bearer token this can be removed
  // Ticket for removal: https://www.pivotaltracker.com/story/show/174805294
  queryWithCredentials<T>(queryString: string, queryVariables?: Object) {
    const endpoint = `${environment.api.baseUrl}/graphql`;
    const headers = this.getHeaders();
    const body = {
      query: queryString,
      variables: queryVariables,
    };
    return this.http
      .post<GraphQLResponse<T>>(endpoint, body, {
        headers,
        withCredentials: true,
      })
      .pipe(mapGraphQLResponse<T>());
  }

  private getHeaders() {
    const token = this.cookieService.get();
    return new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${token}`);
  }
}
