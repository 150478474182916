<omg-inline-insertion-container [modules]="quillModules"
                                [format]="quillFormat"
                                [formats]="quillFormats"
                                [scrollingContainer]="scrollingContainer"
                                [placeholder]="placeholderText"
                                [control]="control"
                                [singleLine]="singleLine"
                                [classes]="classes"
                                [dataCy]="dataCy"
                                [insertionTriggers]="insertionTriggers"
                                [insertionEventProps]="insertionEventProps"
                                [templateType]="templateType"
                                (editorInitialized)="init.emit({ editor: $event })"
                                (editorBlur)="blurTextBox.emit()"
                                (editorFocus)="focusTextBox.emit()"
                                (editorFocusedOut)="focusoutTextbox.emit()"
                                (contentChanged)="contentChanged($event)"
                                (insertionActiveStateChanged)="setQuillModules($event)">
</omg-inline-insertion-container>
