<form [omgForm]="postFormModel"
      [formGroup]="postFormModel.form"
      [ngClass]="{ 'minimized': isMinimized }"
      om-messaging
      omgTheme>
  <omg-card-title>
    <div class="om-tab-subheading"
         (click)="onMaximize()"
         [expanded]="post.commentable.totalComments > 0"
         omgCollapse
         #commentsRef="omgCollapse"
         om-layout
         om-layout-fill
         om-layout-gutter
         om-layout-align="space-between center">
      <span class="om-icon icon-message-large"></span>
      <omg-editable-label *ngIf="!messageThreadExists && !isMinimized"
                          om-flex
                          name="subject"
                          class="-wrapped-text"
                          formControlName="topic"
                          placeholder="Set message topic"
                          omgStopEvent
                          (focusOn)="trackFieldSelected('Title Field')"
                          (focusOut)="trackFieldUnselected('Title Field')">
      </omg-editable-label>
      <span *ngIf="messageThreadExists || isMinimized"
            class="message-title -wrapped-text"
            om-flex>
        {{post.contentAttributes.topic | slice:0:80}}
      </span>

      <!-- Comment icon / toggle -->
      <omg-collapse-toggle om-layout
                           om-layout-gutter-mini
                           om-layout-align="space-between center"
                           class="collapse-with-flex"
                           *ngIf="!docked"
                           (click)="trackCommentToggle($event)">
        <span>{{post.commentable.totalComments}}</span>
        <omg-collapsed disableAnimation="true">
          <span class="om-icon icon-comment clickable"></span>
        </omg-collapsed>
        <omg-expanded disableAnimation="true">
          <span class="om-icon icon-comment-filled clickable"></span>
        </omg-expanded>
      </omg-collapse-toggle>

      <!-- Messaging tab controls -->
      <div om-layout
           om-layout-gutter>
        <span class="clickable om-icon -large icon-minimize"
              omgStopEvent="click"
              *ngIf="!(isMinimized || !docked)"
              (click)="onSetMinimized(true)">
        </span>
        <span class="clickable om-icon -large icon-maximize"
              omgStopEvent="click"
              *ngIf="isMinimized"
              (click)="onMaximize()">
        </span>
        <span class="clickable om-icon icon-close"
              omgStopEvent="click"
              (click)="onClose()">
        </span>
      </div>
    </div>
  </omg-card-title>

  <omg-card-body *ngIf="!isMinimized">
    <div #scrollableContainer>
      <omg-expanded [collapseProvider]="commentsRef">
        <omg-comments [commentable]="post.commentable"
                      (commentAdded)="onCommentUpdate('add')"
                      (commentRemoved)="onCommentUpdate('remove')"></omg-comments>
      </omg-expanded>
      <omg-task-assignment *ngIf="(hasIncompleteTodo() | ngrxPush) && !docked"
                           (assigneeUpdated)="onAssigneeUpdated($event)"
                           [todo]="todo$"
                           class="om-task-assignment-container">
        <div class="padding-normal no-top-padding"
             *ngIf="todoReassignment && !todoReassignment.feedbackExists">
          <omg-todo-reassignment [reassignment]="todoReassignment"></omg-todo-reassignment>
        </div>
      </omg-task-assignment>
      <omg-task-licensing-body class="om-task-licensing-body-container"
                               *ngIf="showLicensingBodyOverride$ | ngrxPush"
                               [todo]="todo$">
      </omg-task-licensing-body>
      <hr class="-spaced"
          *ngIf="showLicensingBodyOverride$ | ngrxPush">
      <div class="om-list">
        <div class="om-item"
             *ngIf="!docked && !post.draft">
          <omg-messaging-comment [postedBy]="post.contentAttributes.postedBy"
                                 [text]="post.contentAttributes.text"
                                 [updatedAt]="post.sentAt || post.updatedAt"
                                 [s3Pointers]="post.s3Pointers"
                                 [alwaysExpand]="sentMessages.length === 0"></omg-messaging-comment>
        </div>
        <ng-container *ngIf="!docked">
          <div class="om-item"
               *ngFor="let message of sentMessages; let last = last;">
            <omg-messaging-comment [postedBy]="message.postedBy"
                                   [text]="message.text"
                                   [updatedAt]="message.sentAt || message.updatedAt"
                                   [alwaysExpand]="last"
                                   [s3Pointers]="message.s3Pointers"></omg-messaging-comment>
          </div>
        </ng-container>

        <div class="om-item"
             *ngIf="inProgressMessage">
          <omg-in-progress-message-header [inProgressMessage]="inProgressMessage"
                                          [isFirst]="!messageThreadExists"
                                          (checkIfShouldUpdate)="onShouldUpdate($event)"
                                          (trackNotifyClick)="trackNotifyClicked()"
                                          (trackAssignToClick)="trackAssignRepliesClicked()">
          </omg-in-progress-message-header>

          <omg-chart-text-box templateType="message"
                              [scrollingContainer]="scrollableContainer"
                              [control]="messageBodyControl"
                              quillFormat="html"
                              placeholderText="Type message here..."
                              [insertionEventProps]="{
                                component: 'Message',
                                subcomponent: 'Note Body',
                                patientTimelinePostId: post.id,
                                messageDraftType: messageDraftType
                              }"
                              (focusTextBox)="trackFieldSelected('Message Field')"
                              (focusout)="trackFieldUnselected('Message Field')">
          </omg-chart-text-box>

          <div class="padding-half-vertical"
               om-layout="vertical"
               om-layout-gutter>

            <ng-container *ngFor="let attachment of inProgressMessage.s3Pointers">
              <omg-file-link *ngIf="!attachment.destroy"
                             [bucket]="attachment.bucket"
                             [key]="attachment.key"
                             [text]="attachment.title"
                             [deletable]="true"
                             (delete)="onDeleteAttachment(attachment, inProgressMessage.s3Pointers)">
              </omg-file-link>
            </ng-container>
          </div>
          <div class="banner -warning"
               *ngIf="formSubmitError && errorMsg">
            {{errorMsg}}
          </div>
          <p class="errors">{{patientWarnings}}</p>
          <omg-form-state when="saveError">
            <ng-template let-change>
              <div class="om-messages"
                   *ngFor="let msg of change.error">
                {{ msg }}
              </div>
            </ng-template>
          </omg-form-state>
        </div>
        <div class="om-item errors"
             *ngIf="!inProgressMessage">
          This thread is not visible to the patient. To communicate with the patient, please start a new message.
        </div>
      </div>
    </div>
  </omg-card-body>

  <omg-card-footer *ngIf="!isMinimized">
    <div omgCollapse
         [disabled]="!postFormModel.form.valid"
         #actionBarCollapse="omgCollapse">
      <omg-collapsed>
        <div class="action-bar"
             om-collapsed
             om-layout
             om-layout-align="space-between center">
          <div om-layout
               om-layout-gutter
               om-layout-fill>
            <!-- Send with Task Handling -->
            <ng-container *ngIf="canSendAndFinishTask | ngrxPush">
              <omg-form-state>
                <ng-template let-change>
                  <omg-split-button [items]="canSendAndFinishTaskActions"
                                    [label]="isSavingOrUploading ? 'Saving...' : 'Send &amp; Finish Task'"
                                    [disabled]="isSavingOrUploading"
                                    id="canSendAndFinishTaskActionsMenu"
                                    [menuStyle]="{ 'width': 'auto' }"
                                    (labelClick)="onSend('finish')"
                                    [ngClass]="{ '-processing': isSavingOrUploading }"
                                    variant="primary"></omg-split-button>
                </ng-template>
              </omg-form-state>
            </ng-container>

            <!-- Finish Task -->
            <ng-container *ngIf="canFinishTaskOnly | ngrxPush">
              <button id="finish-task"
                      omgButton
                      variant="secondary"
                      (click)="onCompleteTodo()">Finish Task</button>
            </ng-container>

            <!-- Send without any task -->
            <ng-container *ngIf="canSendOnly | ngrxPush">
              <omg-form-state>
                <ng-template let-change>
                  <button omgButton
                          variant="primary"
                          class="om-autosave-button"
                          (click)="onSend('finish')"
                          [ngClass]="{ '-processing': isSavingOrUploading }"
                          [disabled]="isSavingOrUploading || !(patientAcceptsDigitalCommunications | ngrxPush)">
                    {{ isSavingOrUploading ? 'Saving...' : 'Send Message' }}
                  </button>
                </ng-template>
              </omg-form-state>
              <button omgButton
                      variant="secondary"
                      *ngIf="canReopenTask | ngrxPush"
                      (click)="onReopenTodo()">Reopen Task</button>
            </ng-container>

            <omg-messaging-product-specialist-options [post]="showDeleteDraft ? inProgressMessage : post">
            </omg-messaging-product-specialist-options>
            <button omgButton
                    variant="link"
                    data-cy="delete-draft"
                    (click)="onDeleteDraft()"
                    [disabled]="isSavingOrUploading"
                    *ngIf="showDeleteDraft"
                    (click)="trackDeleteDraftClicked()">
              Delete Draft
            </button>
          </div>
          <div *ngIf="inProgressMessage"
               om-flex="25"
               om-layout
               om-layout-align="end"
               om-layout-gutter-large
               om-layout-fill>
            <omg-file-upload-button *ngIf="!docked"
                                    (fileChosen)="onAddAttachments($event)">
            </omg-file-upload-button>
          </div>
        </div>
      </omg-collapsed>
    </div>
  </omg-card-footer>
</form>
