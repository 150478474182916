import { Component, Input } from '@angular/core';

import { Report } from '../../shared/pdmp.type';

@Component({
  selector: 'omg-pdmp-report-container',
  templateUrl: './report-container.component.html',
  styleUrls: ['./report-container.component.scss'],
})
export class PdmpReportContainerComponent {
  @Input() report: Report;

  constructor() {}
}
