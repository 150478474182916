<div class="pdmp-report-info">
  <button type="button"
          omgButton
          variant="primary"
          class="overlay-top"
          [disabled]="!report.stateIsParticipating">
    Open in a new tab
    <i class="fa-regular fa-arrow-up-right-from-square new-tab-icon"></i>
  </button>
  <p class="report-for-text">Report for {{report.requestedLocation}}</p>
  <omg-pdmp-last-viewed-by-view [state]="report.requestedLocation"></omg-pdmp-last-viewed-by-view>
  <omg-pdmp-narx-scores-view [narxScores]="report.narxScores"></omg-pdmp-narx-scores-view>
</div>
<div class="report-container">
  <ng-template *ngIf="report.stateIsParticipating; else noReportNonParticipatingError"
               [ngTemplateOutlet]="report.reportUrl ? reportContents : reportErrors">
  </ng-template>
</div>

<ng-template #reportContents>
  <omg-pdmp-report-view [reportUrl]="report.reportUrl"
                        [correlationId]="report.correlationId"></omg-pdmp-report-view>
</ng-template>

<ng-template #reportErrors>
  <div class="error-text">
    <div *ngFor="let error of report.errors">
      {{error}}
    </div>
  </div>
</ng-template>

<ng-template #noReportNonParticipatingError>
  <div class="error-text">
    <div>
      Report not available for requested location.
      <br />
      Review state report for patient outside of 1Life.
    </div>
  </div>
</ng-template>
