import {
  Directive,
  EventEmitter,
  Host,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  Optional,
  Output,
  SimpleChanges,
} from '@angular/core';

import { CollapseProviderDirective } from './collapse-provider.directive';

type CollapseToggleAction = 'expand' | 'collapse';

@Directive({
  selector: 'omg-collapse-toggle, [omgCollapseToggle]',
})
export class CollapseToggleDirective implements OnChanges {
  // eslint-disable-next-line  @angular-eslint/no-input-rename
  @Input('omgCollapseToggle') toggleAction: CollapseToggleAction = null;
  @Input() collapseProvider: CollapseProviderDirective;
  @Output() toggle = new EventEmitter<boolean>();

  constructor(
    @Optional()
    @Host()
    public collapseProviderDirective: CollapseProviderDirective,
  ) {
    this.setProvider();
  }

  ngOnChanges(changes: SimpleChanges) {
    const { collapseProvider } = changes;
    if (collapseProvider) {
      this.setProvider();
    }
  }

  private setProvider() {
    this.collapseProvider =
      this.collapseProvider || this.collapseProviderDirective;
  }

  @HostBinding('class.om-collapse-toggle')
  private get toggleClass() {
    return !this.toggleAction;
  }

  @HostBinding('class.om-collapse-collapse')
  private get toggleCollapseClass() {
    return this.toggleAction === 'collapse';
  }

  @HostBinding('class.om-collapse-expand')
  private get toggleExpandClass() {
    return this.toggleAction === 'expand';
  }

  @HostListener('click', ['$event'])
  onClick(event: any) {
    if (this.ignoreToggleAction()) {
      return;
    }

    this.collapseProvider.collapse.toggle();

    if (event && event.stopPropagation) {
      event.stopPropagation();
    }

    this.toggle.emit(this.collapseProvider.expanded);
  }

  private ignoreToggleAction() {
    const expanded = this.collapseProvider.expanded;

    if (
      (this.toggleAction === 'expand' && expanded) ||
      (this.toggleAction === 'collapse' && !expanded)
    ) {
      return true;
    }

    return false;
  }
}
