<div *ngIf="pdmpInfo$ | ngrxPush as pdmpInfo; else loadingPdmpReport">
  <div *ngIf="!isLoading; else loadingPdmpReport">
    <ng-container *ngIf="pdmpInfo.success; else reportRequestFailure">
      <ng-container *ngIf="pdmpInfo.reports.length > 1; else singleReport">
        <omg-tabs spaceEqually="true">
          <omg-tab [heading]="report.headingText"
                   noTextTransformHeading="true"
                   displayNoneWhenInactive="true"
                   *ngFor="let report of pdmpInfo.reports">
            <div class="single-report-container">
              <omg-pdmp-report-container [report]="report"></omg-pdmp-report-container>
            </div>
          </omg-tab>
        </omg-tabs>
      </ng-container>
      <ng-template #singleReport>
        <div class="single-report-container">
          <omg-pdmp-report-container [report]="pdmpInfo.reports[0]"></omg-pdmp-report-container>
        </div>
      </ng-template>
    </ng-container>
  </div>
</div>
<ng-template #loadingPdmpReport>
  <div class="loading-container">
    <p class="display-text">
      Report(s) will display here
    </p>
    <i class="fa-regular fa-spinner fa-spin-pulse"></i>
  </div>
</ng-template>

<ng-template #reportRequestFailure>
  <div class="error-text">
    <div>
      Unable to display report due to error
    </div>
    <button type="button"
            omgButton
            variant="secondary"
            class="reload-button"
            [disabled]="false"
            (click)="onReloadReportClick()">
      Reload report
    </button>
  </div>
</ng-template>
