import { Injectable } from '@angular/core';
import { createFeatureSelector, createSelector, select } from '@ngrx/store';

import { StateSelector } from '@app/core/store/shared/state';

import { ProcedureCapture } from '../shared/historical-procedure-capture.type';
import { HistoricalProcedureCaptureStoreEntityState } from './historical-procedure-capture.reducer';

export class HistoricalProcedureCaptureSelectors extends StateSelector<
  ProcedureCapture
> {
  protected selectState = createFeatureSelector<
    HistoricalProcedureCaptureStoreEntityState
  >(this.stateConfig.statePath);

  protected selectDocumentsLoading = createSelector(
    this.selectState,
    state => state.documentsLoading,
  );

  protected selectDocumentErrors = createSelector(
    this.selectState,
    state => state.documentErrors,
  );

  protected selectExpandedDocumentForms = createSelector(
    this.selectState,
    state => state.expandedDocumentForms,
  );

  protected selectByDocumentId = createSelector(
    this.selectEntities,
    (procedureCaptures: ProcedureCapture[], { documentId }) =>
      procedureCaptures.find(
        procedureCapture => procedureCapture.documentId === documentId,
      ),
  );

  protected selectLoadingByDocumentId = createSelector(
    this.selectDocumentsLoading,
    (documentsLoading, { documentId }) => documentsLoading.includes(documentId),
  );

  protected selectErrorByDocumentId = createSelector(
    this.selectDocumentErrors,
    (documentErrors, { documentId }) => documentErrors[documentId] || null,
  );

  protected selectFormExpandedByDocumentId = createSelector(
    this.selectExpandedDocumentForms,
    (expandedForms, { documentId }) => expandedForms.includes(documentId),
  );

  protected selectIsFormsExpandedByDocumentId = createSelector(
    this.selectExpandedDocumentForms,
    (expandedForms, { documentIds }) =>
      expandedForms.some(docId => documentIds.includes(docId)),
  );

  getByDocumentId(documentId: number) {
    return this.store.pipe(select(this.selectByDocumentId, { documentId }));
  }

  getLoadingByDocumentId(documentId: number) {
    return this.store.pipe(
      select(this.selectLoadingByDocumentId, { documentId }),
    );
  }

  getErrorByDocumentId(documentId: number) {
    return this.store.pipe(
      select(this.selectErrorByDocumentId, { documentId }),
    );
  }

  getFormExpandedByDocumentId(documentId: number) {
    return this.store.pipe(
      select(this.selectFormExpandedByDocumentId, {
        documentId,
      }),
    );
  }

  getIsFormsExpandedByDocumentIds(documentIds: number[]) {
    return this.store.pipe(
      select(this.selectIsFormsExpandedByDocumentId, {
        documentIds,
      }),
    );
  }
}
