import { Injectable } from '@angular/core';
import {
  Action,
  createFeatureSelector,
  createSelector,
  select,
  Store,
} from '@ngrx/store';
import { Observable } from 'rxjs';

import { ProcedureInteraction } from '@app/features/procedure-interaction/shared/procedure-interaction.type';
import { ProcedureType } from '@app/features/procedure-order/shared/procedure-order.type';

import { AppState } from '../../../core/store/store/app-store.reducer';
import { OrderIndication } from '../../../modules/orders/shared/order-indication/order-indication.type';
import {
  ProcedureInteractionState,
  procedureInteractionStatePath,
} from './procedure-interaction.reducer';

const selector = createFeatureSelector<ProcedureInteractionState>(
  procedureInteractionStatePath,
);

@Injectable()
export class ProcedureInteractionSelectors {
  constructor(private store: Store<AppState>) {}

  get commonProcedures(): Observable<ProcedureType[]> {
    return this.store.pipe(
      select(selector),
      select(({ procedureInteraction }) => {
        if (!procedureInteraction || !procedureInteraction.indications) {
          return [];
        }

        return procedureInteraction.indications?.reduce(
          (commonProcedures, indication) =>
            commonProcedures.concat(
              indication.problemType?.commonProcedureTypes?.filter(
                procedureType => procedureType.performable,
              ) || [],
            ),
          [],
        );
      }),
    );
  }

  get(): Observable<ProcedureInteraction | null> {
    return this.store.pipe(select(selector), select('procedureInteraction'));
  }

  error(action: Action): Observable<any> {
    return this.store.pipe(select(selector), select('errors', action.type));
  }

  loading(action: Action): Observable<boolean> {
    return this.store.pipe(select(selector), select('loading', action.type));
  }
}
