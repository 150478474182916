import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { CookieModule } from 'ngx-cookie';

import { ConfigService } from '@app/core/config';

import { apiProviders } from './api/api-providers';
import { AuthModule } from './auth';
import { EncounterModule } from './encounter/encounter.module';
import { errorProviders } from './errors/error-providers';
import { FeatureFlagModule } from './feature-flag/feature-flag.module';
import { LabelPrinterService } from './label-printer/label-printer.service';
import { LoggerService } from './logger/logger.service';
import { throwIfAlreadyLoaded } from './module-import-guard';
import { PatientModule } from './patient/patient.module';
import { ProfileModule } from './profile/profile.module';
import { SearchModule } from './search/search.module';
import { SectionLinkedQueueService } from './section-linked-queue/section-linked-queue.service';
import { sectionLinkedQueueReducer } from './section-linked-queue/store/section-linked-queue.reducer';
import { DocumentTitleSetterService, RouteTracker, services } from './services';
import { AppStoreModule } from './store/app-store.module';
import { versionCheckProviders } from './version-check/version-check';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    CookieModule.forRoot(),
    AppStoreModule,
    StoreModule.forFeature('sectionLinkedQueue', sectionLinkedQueueReducer),
    AuthModule,
    PatientModule,
    SearchModule,
    EncounterModule,
    ProfileModule,
    FeatureFlagModule,
  ],
  providers: [
    LoggerService,
    SectionLinkedQueueService,
    ConfigService,
    DocumentTitleSetterService,
    RouteTracker,
    LabelPrinterService,
    ...apiProviders,
    ...errorProviders,
    ...versionCheckProviders,
    ...services,
  ],
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule,
  ) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
