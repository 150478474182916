import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { FamilyHealthHistoryApiService } from '@app/modules/family-health-history/shared/family-health-history-api.service';

import { SummaryFamilyHealthHistory } from './../../shared/summaries.type';

@Component({
  selector: 'omg-linked-family-health-history',
  templateUrl: './linked-family-health-history.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkedFamilyHealthHistoryComponent {
  @Input() signed = false;
  @Input() familyHealthHistory: SummaryFamilyHealthHistory[];
  @Input() patientId?: number;

  constructor(
    private familyHealthHistoryApiService: FamilyHealthHistoryApiService,
  ) {}

  deleteFromNote() {
    this.familyHealthHistoryApiService.linkSection(this.patientId, false);
  }
}
