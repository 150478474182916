import { Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';

import { DropdownItem } from '@app/shared';
import { DynamicFormGroup } from '@app/utils/forms/base';

import { RxVerificationSelectors } from '../store/rx-verification.selectors';
import {
  mapDevicesToDropdownItems,
  prioritizePushDevices,
} from './rx-verification-utils';
import {
  RxVerificationDevice,
  RxVerificationDeviceTypes,
} from './rx-verification.type';

export class RxVerificationForm extends DynamicFormGroup {
  private unsubscribe$ = new Subject<void>();

  codeEntryRequired: boolean;
  deviceOptions: DropdownItem[];

  constructor(private selectors: RxVerificationSelectors) {
    super();
    this.addControls();
    this.addListeners();
  }

  unsubscribe() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get password(): string {
    return this.controls.get('password').value;
  }

  get device(): RxVerificationDevice {
    return this.controls.get('device').value;
  }

  get code(): string {
    return this.controls.get('code').value;
  }

  private addControls() {
    this.addControl({ name: 'password', validators: [Validators.required] });
    this.addControl({ name: 'device' });
    this.addControl({ name: 'code' });
  }

  private addListeners() {
    this.selectors.status
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(({ sending, polling, complete }) =>
        sending || polling || complete
          ? this.controls.disable()
          : this.controls.enable(),
      );

    this.controls
      .get('device')
      .valueChanges.pipe(
        takeUntil(this.unsubscribe$),
        map(device => device && device.type === RxVerificationDeviceTypes.code),
      )
      .subscribe(required => (this.codeEntryRequired = required));

    this.selectors.devices
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(Boolean),
        map(prioritizePushDevices),
        tap(this.selectDefaultDevice),
        map(mapDevicesToDropdownItems),
      )
      .subscribe(items => (this.deviceOptions = items));
  }

  private selectDefaultDevice = (devices: RxVerificationDevice[]) => {
    if (devices.length < 2) {
      this.controls.get('device').disable();
    }
    this.controls.get('device').patchValue(devices[0]);
  };
}
