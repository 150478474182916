import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Apollo, gql } from 'apollo-angular';
import { take } from 'rxjs/operators';

import { MarkPdmpReportViewedMutationResponse } from '../../shared/pdmp.type';

export const markPdmpReportViewedMutation = gql`
  mutation MarkPdmpReportAsViewed($correlationId: ID!) {
    markPdmpReportAsViewed(input: { correlationId: $correlationId }) {
      success
      mutationErrors {
        messages
        path
      }
    }
  }
`;

@Component({
  selector: 'omg-pdmp-report-view',
  templateUrl: './report-view.component.html',
  styleUrls: ['./report-view.component.scss'],
})
export class PdmpReportViewComponent implements OnInit {
  @Input() reportUrl: string;
  @Input() correlationId: string;
  safeUrl: SafeResourceUrl;

  constructor(private sanitizer: DomSanitizer, private apollo: Apollo) {}

  ngOnInit() {
    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.reportUrl,
    );
    this.apollo
      .use('onelife')
      .mutate<MarkPdmpReportViewedMutationResponse>({
        mutation: markPdmpReportViewedMutation,
        variables: { correlationId: this.correlationId },
      })
      .pipe(take(1))
      .subscribe();
  }
}
