export enum FeatureFlagNames {
  alternateBookingFlowWeb = 'alternate_booking_flow_web',
  analytics = 'analytics',
  autoCheckInCreation = 'auto_check_in_creation',
  autoAdvanceHealthGoalDueDatePreventionAndScreening = 'pop-health-auto-advance-health-goal-due-date',
  autoVisitFollowUpOrderCreation = 'auto_visit_follow_up_order_creation',
  billingDisallowDocumentUploads = 'billing-disallow-document-uploads',
  billingEAndMUpdate = 'billing_e_and_m_update',
  billingPerformProceduresDocuments = 'billing_perform_procedures_documents',
  billingPerformProceduresMeasurements = 'billing-perform-procedures-measurements',
  billingOpenPracticeZemSegment = 'billing-open-practice-zem-segment',
  billingWaystarEligibilityAPI = 'billing-waystar-eligibility-api',
  billingOpenPracticeAdtSync = 'billing-open-practice-adt-sync',
  bhxFollowUpVisitAppointmentTypeAutocomplete = 'follow-up-visit-appointment-type-autocomplete',
  bhxFollowUpVisitIncludesAdminBookedAppointmentTypes = 'follow-up-visit-includes-admin-booked-appointment-types',
  cases = 'cases',
  ccd = 'ccd',
  cehrtMedlinePlusConnectLink = 'cehrt-medline-plus-connect-link',
  chat = 'chat',
  chartAppSyncCommunication = 'chart-app-sync-communication',
  chartProceduresTab = 'chart-procedures-tab',
  connectedDeviceOrders = 'orders-connected-device-orders',
  consultOrderAutomation = 'consult_order_automation',
  commandPalette = 'command-palette-initial-experiment',
  customNetworksForDpc = 'custom-networks-for-dpc',
  derm = 'derm',
  dsActivateNavbarResourceCenterTriggerAlsoRequiresPendoConfiguration = 'ds-activate-navbar-resource-center-trigger-also-requires-pendo-configuration',
  enhancedTimelineSearch = 'enhanced-timeline-search',
  expandTimelineItemAttachToScreeningLogic = 'expand-timeline-items-eligible-to-be-attached-to-screenings',
  frontendPlatformJadeTheme = 'frontend-platform-jade-theme',
  fastOrdersPreventionAndScreening = 'fast-orders-prevention-and-screening',
  genericFollowUpOrders = 'generic_follow_up_orders',
  growthChartColoringPages = 'growth-chart-coloring-pages',
  growthChartsDownSyndrome = 'growth-charts-down-syndrome',
  healthGoalStructuredPatientReported = 'health-goal-structured-patient-reported',
  healthGoalScreeningStructuredResults = 'structured-results-health-goal-screenings',
  healthHistory = 'health_history',
  interopManualAdtTrigger = 'interop_manual_adt_trigger',
  interopSendDmCcd = 'interop_send_dm_ccd',
  labAutoReporting = 'lab_auto_reporting',
  labOrdersAccudraw = 'lab-orders-accudraw',
  labOrdersTestMenuLink = 'lab-orders-test-menu-link',
  linkExternalDocs = 'link-external-docs',
  locationBasedLicensureTaskRouting = 'location-based-licensure-task-routing',
  manuallyAssociateTimelineDocsWithScreenings = 'manually-associate-timeline-docs-with-screenings',
  meds1000CharInstructions = 'meds-1000-char-instructions',
  medsAutoDymoLabel = 'meds-auto-dymo-label',
  medsCancelRx = 'meds-cancel-rx',
  medsChangeRx = 'meds-change-rx',
  medsChangeRxNotesToPharmacist = 'meds-change-rx-notes-to-pharmacist',
  medsCreateSuspectedProblems = 'meds-create-suspected-problems',
  medsDisplayLabAutoreportabilityRules = 'meds_display_lab_autoreportability_rules',
  medsDisplaySmokingStatus = 'meds-display-smoking-status',
  medsSmokingStatusCehrtRequirements = 'meds-smoking-status-cehrt-requirements',
  medsFamilyHealthHistory = 'meds-family-health-history',
  medsFamilyHealthHistoryAddToNote = 'meds-family-health-history-add-to-note',
  medsImplantableDevices = 'meds-implantable-devices',
  medsRxChangeEditor = 'meds_rx_change_editor',
  medsRxRenewalRequestEditor = 'meds-rx-renewal-request-editor',
  medsRxHistoryFeature = 'meds_rx_history_feature',
  medsSmokingCessationCreateSuspectedProblems = 'meds-smoking-cessation-create-suspected-problems',
  medsAllergiesAndReactionsCehrtRequirements = 'meds-allergies-and-reactions-cehrt-requirements',
  medsVaccineOrderDeleteAndEdit = 'meds-vaccine-order-delete-and-edit',
  medsVitalsRangeValidation = 'meds-vitals-range-validation',
  medsPDMP = 'meds-pdmp',
  medsVaccineGrouping = 'meds-vaccine-grouping',
  orderingRemoveIsSelfCheckoutState = 'ordering-remove-is-self-checkout-state',
  memberManagementUi = 'member-management-ui',
  mentalHealthNotePrivacyConsent = 'mental-health-note-privacy-consent',
  mixpanelEventsWithExperimentData = 'mixpanel-events-with-experiment-data',
  mlMatchmaker = 'ml_matchmaker',
  mlMatchmakerProvider = 'ml_matchmaker_provider',
  mlNoteRetitling = 'ml_note_retitling',
  mobileWebEnterpriseReg = 'mobile_web_enterprise_reg',
  new1lifeConsultOrders = 'new_1life_consult_orders',
  new1lifeDualPhlebWorkflow = 'new_1life_dual_phleb_workflow',
  new1lifeEnableDeletingOfficeNote = 'new-1life-enable-deleting-office-note',
  new1lifeMessagingDeletion = 'new_1life_messaging_deletion',
  new1lifeVaccineOrders = 'new_1life_vaccine_orders',
  nonEssentialSidekiqJobs = 'non_essential_sidekiq_jobs',
  noteRedact = 'note-redact',
  patientCheckInModal = 'patient_check_in_modal',
  patientTimeline = 'patient_timeline',
  pcpCommentBoxCollapse = 'pcp-comment-box-collapse',
  pcpCommentByline = 'pcp-comment-byline',
  pedsVitalsWeightForLengthPercentiles = 'peds-vitals-weight-for-length-percentiles',
  pedsVitalsWeightForLengthPercentilesDisplay = 'peds-vitals-weight-for-length-percentiles-display',
  popHealthEnhancedLabResults = 'pop_health_enhanced_lab_results',
  preventiveCareRedesign = 'preventive-care-redesign',
  problemsScrollPositionOnClose = 'problems-scroll-position-on-close',
  providerDepartureEnabled = 'show-provider-departure-date',
  providerStats = 'provider_stats',
  questTestMenuLink = 'quest-test-menu-link',
  registrationEmailOptOut = 'registration_email_opt_out',
  replyToCst = 'reply_to_cst',
  showRealTimeVbcRefreshSignals = 'show-real-time-vbc-refresh-signals',
  sidekiqJobs = 'sidekiq_jobs',
  surescriptsEnabled = 'surescripts_enabled',
  tuneSignalingInPreventionAndScreening = 'tune-signaling-in-prevention-and-screening',
  useAuth0JwtAuthForSearch = 'auth0-jwt-auth-for-onelife-ui-search',
  userInfoPopover = 'user-info-popover',
  vaccineAddMultiStepHistory = 'vaccine-add-multi-step-history',
  video = 'video',
  taskChainingActionBar = 'task-chaining-action-bar',
  childHeadCirc = 'under-3-yo-head-circumference',
  worryScore = 'worry-score',
}

type ValueOf<T> = T[keyof T];
export type FeatureFlag = ValueOf<FeatureFlagNames>;
