import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { Summary } from './../shared/summaries.type';
import { SummaryAction, SummaryActionTypes } from './summaries.actions';

export const summariesStatePath = 'summaries';

export interface SummariesState extends EntityState<Summary> {
  loading: boolean;
  error: any;
  currentSummaryId: number;
}

export function selectSummaryId(summary: Summary): number {
  return summary.id;
}

export const adapter: EntityAdapter<Summary> = createEntityAdapter<Summary>({
  selectId: selectSummaryId,
});

export const initialSummariesState: SummariesState = adapter.getInitialState({
  loading: false,
  error: null,
  currentSummaryId: null,
});

export const { selectEntities } = adapter.getSelectors();

export const getCurrentSummaryId = (
  state: SummariesState = initialSummariesState,
) => state.currentSummaryId;

export function summariesReducer(
  state = initialSummariesState,
  action: SummaryAction,
): SummariesState {
  switch (action.type) {
    case SummaryActionTypes.LOAD_SUMMARY: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }

    case SummaryActionTypes.LOAD_SUMMARY_SUCCESS: {
      return adapter.upsertOne(action.payload, {
        ...state,
        loading: false,
        error: null,
        currentSummaryId: action.payload.id,
      });
    }

    case SummaryActionTypes.LOAD_SUMMARY_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }

    case SummaryActionTypes.SAVE_SUMMARY: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }

    case SummaryActionTypes.SAVE_SUMMARY_SUCCESS: {
      return adapter.addOne(action.payload, {
        ...state,
        loading: false,
        error: null,
        currentSummaryId: action.payload.id,
      });
    }

    case SummaryActionTypes.SAVE_SUMMARY_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }

    case SummaryActionTypes.UPDATE_SUMMARY: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }

    case SummaryActionTypes.UPDATE_TOTAL_COMMENT_COUNT: {
      return adapter.updateOne(action.payload, {
        ...state,
        loading: false,
        error: null,
      });
    }

    case SummaryActionTypes.UPDATE_SUMMARY_SUCCESS: {
      /* Note: updateOne may cause bugs with autosave since
       * we replace the whole summary entity and not individual
       * properties.
       */
      return adapter.updateOne(action.payload, {
        ...state,
        loading: false,
        error: null,
      });
    }

    case SummaryActionTypes.UPDATE_SUMMARY_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }

    case SummaryActionTypes.SIGN_SUMMARY: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }

    case SummaryActionTypes.SIGN_SUMMARY_SUCCESS: {
      return adapter.updateOne(action.payload, {
        ...state,
        loading: false,
        error: null,
      });
    }

    case SummaryActionTypes.SIGN_SUMMARY_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }

    case SummaryActionTypes.DELETE_SUMMARY: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }

    case SummaryActionTypes.DELETE_SUMMARY_SUCCESS: {
      return adapter.removeOne(action.payload.id, {
        ...state,
        loading: false,
        error: null,
      });
    }

    case SummaryActionTypes.DELETE_SUMMARY_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }

    case SummaryActionTypes.REDACT_SUMMARY: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }

    case SummaryActionTypes.REDACT_SUMMARY_SUCCESS: {
      return adapter.updateOne(action.payload, {
        ...state,
        loading: false,
        error: null,
      });
    }

    case SummaryActionTypes.REDACT_SUMMARY_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }

    case SummaryActionTypes.LINK_PATIENT_ALLERGIES: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }

    case SummaryActionTypes.LINK_PATIENT_ALLERGIES_SUCCESS: {
      // Need to copy the array b/c `action.payload.patientAllergies` is readonly
      // and javascript `.sort` is in-place
      const patientAllergies = [
        ...action.payload.patientAllergies,
      ].sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
      const changes = {
        ...action.payload,
        patientAllergies,
      };
      return adapter.updateOne(
        {
          id: state.currentSummaryId,
          changes,
        },
        {
          ...state,
          loading: false,
          error: null,
        },
      );
    }

    case SummaryActionTypes.LINK_PATIENT_ALLERGIES_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }

    case SummaryActionTypes.LINK_PROBLEMS: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }

    case SummaryActionTypes.LINK_PROBLEMS_SUCCESS: {
      return adapter.updateOne(
        {
          id: state.currentSummaryId,
          changes: { ...action.payload },
        },
        {
          ...state,
          loading: false,
          error: null,
        },
      );
    }

    case SummaryActionTypes.LINK_PROBLEMS_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }

    case SummaryActionTypes.LINK_ASSESSED_PROBLEMS: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }

    case SummaryActionTypes.LINK_ASSESSED_PROBLEMS_SUCCESS: {
      return adapter.updateOne(
        {
          id: state.currentSummaryId,
          changes: { ...action.payload },
        },
        {
          ...state,
          loading: false,
          error: null,
        },
      );
    }

    case SummaryActionTypes.LINK_ASSESSED_PROBLEMS_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }

    case SummaryActionTypes.LINK_FAMILY_HEALTH_HISTORY: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }

    case SummaryActionTypes.LINK_FAMILY_HEALTH_HISTORY_SUCCESS: {
      let familyHealthHistory = null;

      // Need to copy the array b/c `action.payload.familyHealthHistory` is readonly
      // and javascript `.sort` is in-place
      if (action.payload.familyHealthHistory) {
        familyHealthHistory = [
          ...action.payload.familyHealthHistory,
        ].sort((a, b) =>
          a.updatedAt < b.updatedAt ? 1 : b.updatedAt < a.updatedAt ? -1 : 0,
        );
      }

      const changes = {
        ...action.payload,
        familyHealthHistory,
      };
      return adapter.updateOne(
        {
          id: state.currentSummaryId,
          changes,
        },
        {
          ...state,
          loading: false,
          error: null,
        },
      );
    }

    case SummaryActionTypes.LINK_FAMILY_HEALTH_HISTORY_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }

    case SummaryActionTypes.LINK_HEALTH_GOALS: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }

    case SummaryActionTypes.LINK_HEALTH_GOALS_SUCCESS: {
      return adapter.updateOne(
        {
          id: state.currentSummaryId,
          changes: { ...action.payload },
        },
        {
          ...state,
          loading: false,
          error: null,
        },
      );
    }

    case SummaryActionTypes.LINK_HEALTH_GOALS_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }

    case SummaryActionTypes.LINK_HEALTH_BACKGROUND: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }

    case SummaryActionTypes.LINK_HEALTH_BACKGROUND_SUCCESS: {
      return adapter.updateOne(
        {
          id: state.currentSummaryId,
          changes: { ...action.payload },
        },
        {
          ...state,
          loading: false,
          error: null,
        },
      );
    }

    case SummaryActionTypes.LINK_HEALTH_BACKGROUND_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }

    case SummaryActionTypes.LINK_MEDICATIONS: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }

    case SummaryActionTypes.LINK_MEDICATIONS_SUCCESS: {
      return adapter.updateOne(
        {
          id: state.currentSummaryId,
          changes: { ...action.payload },
        },
        {
          ...state,
          loading: false,
          error: null,
        },
      );
    }

    case SummaryActionTypes.LINK_MEDICATIONS_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }

    case SummaryActionTypes.LINK_VACCINATIONS: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }

    case SummaryActionTypes.LINK_VACCINATIONS_SUCCESS: {
      return adapter.updateOne(
        {
          id: state.currentSummaryId,
          changes: { ...action.payload },
        },
        {
          ...state,
          loading: false,
          error: null,
        },
      );
    }

    case SummaryActionTypes.LINK_VACCINATIONS_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }

    case SummaryActionTypes.UNLINK: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }

    case SummaryActionTypes.UNLINK_SUCCESS: {
      return adapter.updateOne(
        {
          id: state.currentSummaryId,
          changes: { ...action.payload },
        },
        {
          ...state,
          loading: false,
          error: null,
        },
      );
    }

    case SummaryActionTypes.UNLINK_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }

    case SummaryActionTypes.SET_CURRENT_SUMMARY_ID: {
      return {
        ...state,
        currentSummaryId: action.payload,
      };
    }

    case SummaryActionTypes.CLOSE_WORKSPACE_ITEM: {
      return {
        ...state,
        currentSummaryId: null,
      };
    }

    case SummaryActionTypes.SAVE_ADDENDUM: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }

    case SummaryActionTypes.SAVE_ADDENDUM_SUCCESS: {
      return adapter.updateOne(
        {
          id: state.currentSummaryId,
          changes: {
            summaryAddendums: [
              ...state.entities[state.currentSummaryId].summaryAddendums,
              { ...action.payload },
            ],
          },
        },
        {
          ...state,
          loading: false,
          error: null,
        },
      );
    }

    case SummaryActionTypes.SAVE_ADDENDUM_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }

    case SummaryActionTypes.DELETE_SERVICE_TICKET_ITEM: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }

    case SummaryActionTypes.DELETE_SERVICE_TICKET_ITEM_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: null,
      };
    }

    case SummaryActionTypes.DELETE_SERVICE_TICKET_ITEM_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }

    case SummaryActionTypes.START_APPOINTMENT_FROM_NOTE: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }

    case SummaryActionTypes.START_APPOINTMENT_FROM_NOTE_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: null,
      };
    }

    case SummaryActionTypes.START_APPOINTMENT_FROM_NOTE_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }

    case SummaryActionTypes.REFRESH_NOTE: {
      return {
        ...state,
        loading: true,
      };
    }

    default: {
      return { ...state };
    }
  }
}
