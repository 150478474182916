import {
  ProcedureOrderListItem,
  VaccineOrderListItem,
} from '@app/features/orders-list/shared/order-list.type';
import { ProcedureOrderDisplayStatus } from '@app/features/procedure-order/shared/procedure-order.type';
import { ProcedureOrder } from '@app/features/procedure-order/shared/procedure-order.type';
import { ContactSearchResult } from '@app/modules/orders/shared/consultant-search/consultant-search.type';
import { LabOrder } from '@app/modules/orders/shared/lab-order.type';
import {
  Order,
  OrderStatus,
  OrderTypes,
  OrderWithSupportingDocuments,
} from '@app/modules/orders/shared/order.type';
import { dateIsString, differenceInWeeks, parseISO, toDate } from '@app/utils';

type OrderStatusClassMapping = {
  [status in OrderStatus | ProcedureOrderDisplayStatus]: string;
};

export const orderStatusClassMapping: Partial<OrderStatusClassMapping> = {
  approved: '',
  cancelled: '-inactive',
  collected: '-success',
  completed: '-success',
  declined: '-inactive',
  ready: '',
  redacted: '-inactive',
  reviewable: '',
  reviewed: '-success',
  sent: '-success',
  signed: '',
  unsigned: '',
  'patient completed': '-success',
};

export const isLabOrderDue = (order: LabOrder): boolean => {
  let dueDate: Date;
  const today = new Date();

  if (
    !(<LabOrder>order).scheduledDate ||
    order.status === 'completed' ||
    order.status === 'redacted'
  ) {
    return false;
  }
  dueDate = new Date((<LabOrder>order).scheduledDate);

  return dueDate <= today && order.status === 'signed';
};

export const isProcedureOrderDue = (order: ProcedureOrderListItem): boolean => {
  let dueDate: Date;
  const today = new Date();
  if (order.status === 'signed') {
    return true;
  }
  if (
    !(<ProcedureOrderListItem>order).dueDate ||
    order.status === 'completed' ||
    order.status === 'redacted' ||
    order.status === 'reviewed'
  ) {
    return false;
  }
  dueDate = new Date((<ProcedureOrderListItem>order).dueDate);

  return dueDate <= today;
};

export const isVaccineOrderDue = (order: VaccineOrderListItem): boolean => {
  let dueDate: Date;
  const today = new Date();
  if (!(<VaccineOrderListItem>order).dateDue || order.isActive === false) {
    return false;
  }
  dueDate = new Date((<VaccineOrderListItem>order).dateDue);

  return dueDate <= today && order.status === 'signed';
};

export const whenPatientRemindedText = (
  dueOnDate: Date | string,
  amountOfWeeksThreshold?: number,
): string => {
  const oneWeek = 1;
  const today = new Date();
  const dueDate = dateIsString(dueOnDate)
    ? parseISO(`${dueOnDate}`)
    : toDate(<Date>dueOnDate);
  const weekDifference = differenceInWeeks(dueDate, today);
  const timeFrame =
    amountOfWeeksThreshold && weekDifference > amountOfWeeksThreshold
      ? 'month'
      : 'week';

  return weekDifference < oneWeek
    ? ''
    : `Patient will be reminded 1 ${timeFrame} before due date`;
};

export enum PageLimits {
  Fax = 30,
  'Direct Message' = 100,
}

export type TransmissionMethod = keyof typeof PageLimits;

export const getTransmissionMethod = (
  contact: ContactSearchResult,
): TransmissionMethod =>
  contact.directMessagingEnabled ? 'Direct Message' : 'Fax';

export const getPageCount = (order: OrderWithSupportingDocuments) =>
  order.supportingDocuments.reduce(
    (total, doc) => total + doc.approximatePdfPages,
    0,
  );

export const getPageLimits = (contact: ContactSearchResult) =>
  PageLimits[getTransmissionMethod(contact)];

export const getOrderChangeMessage = (status: string, orderRejected: boolean) =>
  orderRejected
    ? 'This order has been rejected and reassigned to the last assignee.'
    : `Your order has been ${status}`;
const noValidServiceTicketErrors = [
  'Closed service ticket',
  'No service ticket',
];

export const isNoValidServiceTicketError = (errorMsg: any) =>
  typeof errorMsg === 'string' &&
  noValidServiceTicketErrors.some((ticketErr: string) =>
    errorMsg.startsWith(ticketErr),
  );

const baseInactiveStates = [
  'completed',
  'redacted',
  'declined',
  'patient completed',
  'patient_completed',
];

const consultOrderInactive = (
  status: OrderStatus,
  inactiveStates?: OrderStatus[],
): boolean => {
  return inactiveStates
    ? inactiveStates.includes(status)
    : baseInactiveStates.includes(status);
};

const procedureOrderInactive = (
  order: Order,
  inactiveStates?: OrderStatus[],
): boolean => {
  const procedureInactiveStates = [...baseInactiveStates, 'reviewed'];
  const reviewedInternalProcedureOrder =
    order.status === 'reviewed' && (<ProcedureOrder>order).isInternal;
  return (
    !order.reviewable &&
    (inactiveStates
      ? inactiveStates.includes(order.status) || reviewedInternalProcedureOrder
      : procedureInactiveStates.includes(order.status))
  );
};

const labOrderInactive = (status: OrderStatus): boolean => {
  const labOrderInactiveStates = [
    'cancelled',
    'collected',
    'declined',
    'expired',
  ];
  return labOrderInactiveStates.includes(status);
};

export const orderInactive = (order: Order): boolean => {
  const inactiveStates = <OrderStatus[]>[
    'completed',
    'patient completed',
    'declined',
    'expired',
  ];
  switch (order.type) {
    case OrderTypes.ConsultOrder:
      return consultOrderInactive(order.status, [
        ...inactiveStates,
        'redacted',
      ]);
    case OrderTypes.ProcedureOrder:
      return procedureOrderInactive(order, [...inactiveStates, 'redacted']);
    case OrderTypes.VaccineOrder:
      return [...inactiveStates, 'cancelled'].includes(order.status);
    case OrderTypes.GenericFollowUpOrder:
    case OrderTypes.BasicFollowUpOrder:
    case OrderTypes.VisitFollowUpOrder:
    case OrderTypes.SurveyOrder:
      return ['completed', 'declined', 'expired'].includes(order.status);
    case OrderTypes.LabOrder:
      return labOrderInactive(order.status);
    default:
      return false;
  }
};

// TODO: Deprecate after ordersTabRedesign feature is shipped
export const isInactiveOrder = (order: Order): boolean => {
  switch (order.type) {
    case OrderTypes.ConsultOrder:
      return consultOrderInactive(order.status);
    case OrderTypes.ProcedureOrder:
      return procedureOrderInactive(order);
    case OrderTypes.VaccineOrder:
      return !(<VaccineOrderListItem>order).isActive;
    case OrderTypes.LabOrder:
      return labOrderInactive(order.status);
    default:
      return false;
  }
};

export const isOrderDue = (order: Order): boolean => {
  if (isInactiveOrder(order)) return false;
  switch (order.type) {
    case OrderTypes.ConsultOrder:
    case OrderTypes.ProcedureOrder:
      return isProcedureOrderDue(<ProcedureOrderListItem>order);
    case OrderTypes.VaccineOrder:
      return isVaccineOrderDue(<VaccineOrderListItem>order);
    case OrderTypes.LabOrder:
      return isLabOrderDue(<LabOrder>order);
    case OrderTypes.BasicFollowUpOrder:
    default:
      return false;
  }
};

const procedureOrderPerformable = (order: ProcedureOrder): boolean =>
  order.status === 'sent' && order.isInternal;

export const orderPerformable = (order: Order): boolean => {
  switch (order.type) {
    case OrderTypes.ProcedureOrder:
      return procedureOrderPerformable(<ProcedureOrder>order);
    case OrderTypes.LabOrder:
    case OrderTypes.VaccineOrder:
      return order.status === 'signed';
    default:
      return false;
  }
};

export const orderActive = (order: Order): boolean => {
  const baseActionStates: OrderStatus[] = ['signed', 'unsigned', 'approved'];
  switch (order.type) {
    case OrderTypes.ConsultOrder:
      return [...baseActionStates, 'sent'].includes(order.status);
    case OrderTypes.ProcedureOrder:
      return (
        baseActionStates.includes(order.status) ||
        (order.status === 'sent' && !(<ProcedureOrder>order).isInternal)
      );
    case OrderTypes.SurveyOrder:
    case OrderTypes.GenericFollowUpOrder:
    case OrderTypes.BasicFollowUpOrder:
    case OrderTypes.VisitFollowUpOrder:
      return order.status === 'signed';
    case OrderTypes.VaccineOrder:
    case OrderTypes.LabOrder:
      return order.status === 'unsigned';
    case OrderTypes.ConnectedDeviceOrder:
      return order.status === 'signed';
    default:
      return false;
  }
};
