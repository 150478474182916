import { Component, Input, OnInit } from '@angular/core';

import { NarxScore, NarxScoresForDisplay } from '../../shared/pdmp.type';

const mapNarxScoresToDisplayObject = (
  narxScores: NarxScore[],
): NarxScoresForDisplay | null => {
  if (!narxScores) {
    return null;
  }

  const findValue = name =>
    narxScores.find(({ type }) => type === name)?.value || '';

  return {
    narcotic: findValue('Narcotics'),
    sedative: findValue('Sedatives'),
    stimulant: findValue('Stimulants'),
    overdose: findValue('Overdose'),
  };
};

@Component({
  selector: 'omg-pdmp-narx-scores-view',
  templateUrl: './narx-scores-view.component.html',
  styleUrls: ['./narx-scores-view.component.scss'],
})
export class PdmpNarxScoresViewComponent implements OnInit {
  @Input() narxScores: NarxScore[];
  narxScoresForDisplay: NarxScoresForDisplay;

  ngOnInit() {
    this.narxScoresForDisplay = mapNarxScoresToDisplayObject(this.narxScores);
  }
}
