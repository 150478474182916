import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AnalyticsEvent } from '@app/core/analytics/analytics.type';
import { TodoReassignmentFeedbackService } from '@app/modules/messaging/shared/todo-reassignment-feedback.service';
import { atLeastOneValue } from '@app/shared/forms/validators/at-least-one-value-validator';

enum State {
  initial = 'initial',
  gratitude = 'gratitude',
  done = 'done',
}

@Component({
  selector: 'omg-todo-reassignment-feedback',
  templateUrl: './todo-reassignment-feedback.component.html',
  styleUrls: ['./todo-reassignment-feedback.component.scss'],
})
export class TodoReassignmentFeedbackComponent implements OnDestroy {
  private readonly gratitudeDisplayPeriodMs = 3000;

  @Input() reassignmentId: string;
  @Output() cancel = new EventEmitter<void>();

  state = State.initial;
  State = State;
  form: UntypedFormGroup;
  readonly otherCommentsFormName = 'other';
  readonly categoryFormName = 'category';

  private timer;

  constructor(
    private todoReassignmentFeedbackService: TodoReassignmentFeedbackService,
    private changeDetector: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder,
    private analytics: AnalyticsService,
  ) {
    this.form = this.formBuilder.group(
      {
        [this.categoryFormName]: [''],
        [this.otherCommentsFormName]: [''],
      },
      { validators: atLeastOneValue },
    );
  }

  submitFeedback() {
    if (this.form.invalid) {
      return;
    }

    const feedbackCategory = this.form.get(this.categoryFormName).value;
    const feedbackText = this.form.get(this.otherCommentsFormName).value;

    this.todoReassignmentFeedbackService.saveFeedback(
      this.reassignmentId,
      feedbackCategory,
      feedbackText,
    );

    this.analytics.track(AnalyticsEvent.MLFeedbackGiven, {
      workflow: 'Messages',
      component: 'Message',
      subcomponent: 'Send Feedback Button',
      feedbackResponse: feedbackCategory,
      inputString: feedbackText,
    });

    this.state = State.gratitude;
    this.timer = setTimeout(() => {
      this.state = State.done;
      this.changeDetector.detectChanges();
    }, this.gratitudeDisplayPeriodMs);
  }

  ngOnDestroy(): void {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }
}
