import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { Profile, ProfileIdentity, ProfileSelectors } from '@app/core';
import { FeatureFlagNames } from '@app/core/feature-flag/shared/feature-flag.type';
import { LaunchDarklyService } from '@app/core/launch-darkly/launchdarkly.service';
import { FocusService } from '@app/shared/directives/focus/focus.service';

import { InternalUserPreferencesService } from '../../shared/internal-user-preferences.service';
import { InternalUserAssignee } from '../../shared/internal-user-preferences.type';
import { MessagingService } from '../../shared/messaging.service';
import { Message, Post } from '../../shared/messaging.type';

@Component({
  selector: 'omg-in-progress-message-header',
  templateUrl: './in-progress-message-header.component.html',
  styleUrls: ['./in-progress-message-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InProgressMessageHeaderComponent implements OnInit, OnChanges {
  @Input() inProgressMessage: Message | Post;
  @Input() isFirst: boolean;

  @Output() checkIfShouldUpdate = new EventEmitter<{
    data: Message | Post;
    save: boolean;
  }>();
  @Output() trackNotifyClick = new EventEmitter();
  @Output() trackAssignToClick = new EventEmitter();

  highPriority: boolean;
  profile$: Observable<Profile>;
  primaryIdentity$: Observable<ProfileIdentity>;
  assigneesItems$: Observable<InternalUserAssignee[]>;
  notifyItems$: Observable<InternalUserAssignee[]>;

  showNotifyField = false;
  showAssignField = false;
  showPriorityField = false;

  notifyFocusOnKey = 'currentMessage-notify';
  assignFocusOnKey = 'currentMessage-assign';
  priorityFocusOnKey = 'currentMessage-priority';
  initialTaskPriority: number;

  constructor(
    private profileSelectors: ProfileSelectors,
    private internalUserPreferencesService: InternalUserPreferencesService,
    private messagingService: MessagingService,
    private focusService: FocusService,
    private launchDarklyService: LaunchDarklyService,
  ) {}

  ngOnInit() {
    this.profile$ = this.profileSelectors.profile;
    this.primaryIdentity$ = this.profileSelectors.primaryIdentity;
    this.initialTaskPriority = this.inProgressMessage.taskPriority;
    this.highPriority = this.initialTaskPriority === 1;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes?.inProgressMessage?.currentValue?.replyTo?.name) {
      this.fetchDefaultAssignee();
    }
  }

  fetchDefaultAssignee() {
    this.internalUserPreferencesService
      .get()
      .pipe(take(1))
      .subscribe(preferences => {
        this.setReplyTo(preferences.defaultSendAsAssignee, true);
      });
  }

  setReplyTo(assignee: InternalUserAssignee, update = true) {
    this.inProgressMessage.replyTo = assignee;
    if (update) {
      this.checkUpdate(update);
    }
  }

  setNotificationRecipient(assignee: InternalUserAssignee) {
    this.inProgressMessage.notificationRecipient = {
      assigneeIdent: assignee.identifier,
      ...assignee,
    };
    this.checkUpdate();
  }

  searchForAssignees(text: string) {
    if (text && text.length > 1) {
      this.assigneesItems$ = this.messagingService.searchTaskAssignees(text);
    }
  }

  searchForNotify(text: string) {
    if (text && text.length > 1) {
      this.notifyItems$ = this.messagingService.searchTaskAssignees(text);
    }
  }

  filterTaskAssignees(term: string, item: any) {
    // return true so that all elasticsearch autocomplete results are displayed
    return true;
  }

  startNotify() {
    this.trackNotifyClick.emit();
    this.showNotifyField = true;
    this.focusService.setFocus(this.notifyFocusOnKey);
  }

  startAssign() {
    this.trackAssignToClick.emit();
    this.showAssignField = true;
    this.focusService.setFocus(this.assignFocusOnKey);
  }

  startPriority() {
    this.showPriorityField = true;
    this.focusService.setFocus(this.priorityFocusOnKey);
  }

  updateTaskPriority(highPriority: boolean) {
    this.inProgressMessage.taskPriority = highPriority
      ? 1
      : this.initialTaskPriority;
    this.checkUpdate();
  }

  private checkUpdate(save: boolean = true) {
    this.checkIfShouldUpdate.emit({ data: this.inProgressMessage, save });
  }
}
