import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { PatientSelectors } from '@app/core';
import { ApiService } from '@app/core/api/api.service';
import { PatientEntityApiService } from '@app/core/store/shared/entity-api-service';
import { SummariesActions } from '@app/features/summaries/store/summaries.actions';

import {
  FamilyHealthHistoryNotes,
  FamilyHealthHistoryReview,
  FamilyMember,
} from './family-health-history.type';

// includes getById(), getAll(), update(), delete(), and save() by default
@Injectable({
  providedIn: 'root',
})
export class FamilyHealthHistoryApiService extends PatientEntityApiService<
  FamilyMember
> {
  constructor(
    api: ApiService,
    patientSelectors: PatientSelectors,
    private summariesActions: SummariesActions,
  ) {
    super(
      api,
      {
        basePath:
          '/v2/admin/patients/:patientId/family_health_histories/family_members',
        params: null,
      },
      patientSelectors,
    );
  }

  loadFamilyHealthHistoryNotes(): Observable<FamilyHealthHistoryNotes> {
    return this.api.get<FamilyHealthHistoryNotes>(
      this.componentPath('health_background'),
      this.params,
      this.apiConfig,
      true,
    );
  }

  updateFamilyHealthHistoryNotes(
    notes: FamilyHealthHistoryNotes,
  ): Observable<FamilyHealthHistoryNotes> {
    return this.api.update<FamilyHealthHistoryNotes>(
      this.componentPath('health_background'),
      notes,
      this.apiConfig,
      true,
    );
  }

  markAsReviewed(): Observable<FamilyHealthHistoryReview> {
    return this.api.save<FamilyHealthHistoryReview>(
      this.componentPath('reviews'),
      null,
      this.apiConfig,
      true,
    );
  }

  linkSection(patientId: number, addFamilyHealthHistoryToNote: boolean) {
    this.summariesActions.linkFamilyHealthHistory({
      patientId,
      addFamilyHealthHistoryToNote,
    });
  }

  private componentPath(componentPath: string) {
    return this.basePath.replace('family_members', componentPath);
  }
}
