import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { combineLatest, combineLatestWith, map, Observable, take } from 'rxjs';

import { PatientSelectors } from '@app/core';
import { FullPrescriptionHistoryComponent } from '@app/features/prescription-history/components/full-prescription-history/full-prescription-history.component';
import { PrescriptionHistoryTabs } from '@app/features/prescription-history/shared/prescription-history.type';
import { Renewal } from '@app/features/renewals/shared/renewals.type';
import { PatientPharmacySelectors } from '@app/modules/pharmacy-picker/store/patient-pharmacy.selectors';
import { RxCart } from '@app/modules/rx-cart';
import { PendingNewRx } from '@app/modules/rx-cart';
import { DialogService } from '@app/shared/components/dialog';
import { filterTruthy } from '@app/utils';

import {
  PdmpPatientAndNonParticipatingStatesResponse,
  PdmpStateInfo,
} from '../../shared/pdmp.type';

export const MARK_RX_CART_READY_TO_SIGN = gql`
  mutation MarkRxCartReadyToSign($rxCartId: ID!) {
    markRxCartReadyToSign(input: { rxCartId: $rxCartId }) {
      success
    }
  }
`;

export const PDMP_DEFAULT_REPORT_STATE_QUERY = gql`
  query GetDefaultReportStateInfo($id: ID!) {
    patient(id: $id) {
      id
      defaultPdmpReportState
    }
    pdmpNonParticipatingStates {
      id
      shortName
    }
  }
`;

const mapToRxCartReadyToSignResult = ({
  data: {
    markRxCartReadyToSign: { success },
  },
}): boolean => success;

const mapToPdmpStateInfo = ({
  data: { pdmpNonParticipatingStates, patient },
}) => {
  const defaultPdmpReportState = patient.defaultPdmpReportState;
  const hasParticipatingState = !pdmpNonParticipatingStates.find(
    state => state.shortName === defaultPdmpReportState,
  );

  return {
    pdmpNonParticipatingStates,
    defaultPdmpReportState,
    hasParticipatingState,
  };
};

@Component({
  selector: 'omg-pdmp-dialogue-banner',
  templateUrl: './pdmp-dialogue-banner.component.html',
  styleUrls: ['./pdmp-dialogue-banner.component.scss'],
})
export class PdmpDialogueBannerComponent implements OnInit, OnChanges {
  @Input() patientId: number;
  @Input() cartId: number;
  @Input() cartItems: RxCart['cartItems'] | Array<Renewal>;
  @Output() confirmedReportViewed = new EventEmitter<boolean>();

  isChecked: boolean;
  pdmpStateInfo$: Observable<PdmpStateInfo>;
  controlledSubstancePharmacyStates$: Observable<string>[];
  viewLinkLabel: string;
  confirmationLabel: string;
  viewOutsideLabel: string;
  hasParticipatingStates: boolean = true;

  constructor(
    private dialogService: DialogService,
    private apollo: Apollo,
    private patientSelectors: PatientSelectors,
    private patientPharmacySelectors: PatientPharmacySelectors,
  ) {}

  ngOnInit() {
    this.pdmpStateInfo$ = this.apollo
      .use('onelife')
      .query<PdmpPatientAndNonParticipatingStatesResponse>({
        query: PDMP_DEFAULT_REPORT_STATE_QUERY,
        variables: { id: this.patientId },
      })
      .pipe(map(mapToPdmpStateInfo));

    const items: (PendingNewRx | Renewal)[] = this.cartItems;
    this.controlledSubstancePharmacyStates$ = items
      .filter(item => item.dispensableControlledSubstance)
      .map(item => {
        const pharmacyId =
          'pharmacy' in item ? item.pharmacy.id : item.pharmacyId;
        return this.patientPharmacySelectors.pharmacy(pharmacyId).pipe(
          filterTruthy(),
          map(({ pharmacy }) => pharmacy.address.state),
          take(1),
        );
      });

    combineLatest(this.controlledSubstancePharmacyStates$)
      .pipe(
        take(1),
        combineLatestWith(this.pdmpStateInfo$),
        map(([controlledSubstanceStates, pdmpStateInfo]) => {
          const controlledSubstanceStatesSet: string[] = [
            ...new Set(controlledSubstanceStates),
          ];

          const nonParticipatingStates: string[] = pdmpStateInfo.pdmpNonParticipatingStates
            .map(state => state.shortName)
            .filter(stateName =>
              controlledSubstanceStatesSet.includes(stateName),
            );

          const participatingStates: string[] = controlledSubstanceStatesSet.filter(
            state => !nonParticipatingStates.includes(state),
          );

          return {
            controlledSubstanceStates: controlledSubstanceStatesSet,
            participatingStates,
            nonParticipatingStates,
          };
        }),
      )
      .subscribe(stateInfo => {
        this.hasParticipatingStates =
          stateInfo.controlledSubstanceStates.filter(state =>
            stateInfo.participatingStates.includes(state),
          ).length > 0;
        this.setLabels(stateInfo);
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    // reset PDMP confirmation if the number of controlled substances in rxCart changes
    const cartItemsChanges = changes['cartItems'];
    if (cartItemsChanges.previousValue) {
      const previousControlledSubstanceCount = cartItemsChanges.previousValue.filter(
        item => item.dispensableControlledSubstance,
      ).length;
      const currentControlledSubstanceCount = cartItemsChanges.currentValue.filter(
        item => item.dispensableControlledSubstance,
      ).length;

      if (
        previousControlledSubstanceCount !== currentControlledSubstanceCount
      ) {
        this.isChecked = false;
      }
    }
  }

  onShowFullRxHistoryClick() {
    const dialogRef = this.dialogService.open(
      FullPrescriptionHistoryComponent,
      {
        autoFocus: true,
        disableClose: false,
      },
    );

    if (dialogRef) {
      dialogRef.componentInstance.currentHistoryTab =
        PrescriptionHistoryTabs.pdmp.id;
      dialogRef.componentInstance.rxCartId = this.cartId;
    }
  }

  onConfirmReportViewedChange() {
    if (this.isChecked) {
      this.markAsReadyToSign(this.cartId)
        .pipe(take(1))
        .subscribe(result => {
          this.isChecked = result;
          this.confirmedReportViewed.emit(this.isChecked);
        });
    } else {
      this.confirmedReportViewed.emit(false);
    }
  }

  private markAsReadyToSign = cartId =>
    this.apollo
      .use('onelife')
      .mutate({
        mutation: MARK_RX_CART_READY_TO_SIGN,
        variables: { rxCartId: cartId },
      })
      .pipe(map(mapToRxCartReadyToSignResult));

  formatStateList(states: string[]) {
    // replace last comma with ampersand. eg. CA, IL, NY -> CA, IL & NY
    return states.join(', ').replace(/,([^,]*)$/, ' &' + '$1');
  }

  setLabels(stateInfo) {
    const reportStates: string[] = [];
    const nonReportStates: string[] = [];

    const controlledSubstanceStateList = this.formatStateList(
      stateInfo.controlledSubstanceStates,
    );

    stateInfo.controlledSubstanceStates.forEach(state => {
      if (stateInfo.participatingStates.includes(state)) {
        reportStates.push(state);
      } else {
        nonReportStates.push(state);
      }
    });

    const reportStateList = this.formatStateList(reportStates);
    const nonReportStateList = this.formatStateList(nonReportStates);

    this.viewLinkLabel =
      reportStateList.length > 0
        ? `View Prescription Drug Monitoring Program (PDMP) Report for ${reportStateList}`
        : `Prescription Drug Monitor Program for ${nonReportStateList} not available`;

    this.confirmationLabel = `I have reviewed the report for ${controlledSubstanceStateList}`;

    this.viewOutsideLabel = '';
    if (nonReportStates.length > 0) {
      if (reportStates.length === 0) {
        this.viewOutsideLabel = 'Review state reports outside of 1Life';
      } else {
        this.viewOutsideLabel = `PDMP Reporting for ${this.formatStateList(
          nonReportStates,
        )} not available.  Review state report outside of 1Life`;
      }
    }
  }
}
