import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
export interface ButtonGroupOption {
  label: string;
  value: any;
  click?: Function;
}
@Component({
  selector: 'omg-button-group',
  templateUrl: './button-group.component.html',
  styleUrls: ['./button-group.component.scss'],
})
export class ButtonGroupComponent {
  @Input() options: ButtonGroupOption[];
  @Input() label: string;
  @Input() buttonGroupControl: UntypedFormControl;
  @Input() groupName: string;

  @Output() buttonGroupClick = new EventEmitter<Event>();

  get radioGroupName(): string {
    return this.groupName || 'buttonGroupOption';
  }

  @ViewChild('buttonGroupOption')
  buttonGroupOption: ElementRef<HTMLInputElement>;

  focus(): void {
    this.buttonGroupOption.nativeElement.focus();
  }
}
