<div class="scores-explanation-popover">
  <div class="header">
    <div class="text">Explanation and Guidelines</div>
  </div>
  <div class="body">
    <div class="text">Overdose Risk Score ranges from 000-999</div>
    <div class="text">The overdose risk score (ORS) can be applied in a manner analogous to the daily
      morphine milligram
      equivalent (MME). Consider a naloxone prescription for an ORS score of 450 or higher.</div>
    <a href="https://secure.prep.pmpgateway.net/narx-content/assets/narxcare2/pdf/explain-overdose-risk-score.pdf"
       target="_blank">Learn more</a>
  </div>
</div>
