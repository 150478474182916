import { Component } from '@angular/core';

@Component({
  selector: 'omg-pdmp-narx-scores-explanation',
  templateUrl: './narx-scores-explanation.component.html',
  styleUrls: ['./narx-scores-explanation.component.scss'],
})
export class PdmpNarxScoresExplanationComponent {
  constructor() {}
}
