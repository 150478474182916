import { Component, Input, TemplateRef, ViewChild } from '@angular/core';

import { PopoverOptions, PopoverPlacement } from './popover-config';
import { PopoverRef } from './popover-ref';

@Component({
  selector: 'omg-popover',
  template: `
    <ng-template>
      <span (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()">
        <ng-content></ng-content>
      </span>
    </ng-template>
  `,
})
export class PopoverComponent implements PopoverOptions {
  @Input() title?: string = null;
  @Input() placement: PopoverPlacement;
  @Input() offsetX: number;
  @Input() offsetY: number;
  @Input() width: number | string;
  @Input() height: number | string;
  @Input() enabled = true;
  @Input() withFlexibleDimensions?: boolean = false;

  @ViewChild(TemplateRef, { static: true }) content: TemplateRef<any>;

  popoverRef: PopoverRef;

  close() {
    if (this.popoverRef) {
      this.popoverRef.close();
    }
  }

  onMouseEnter() {
    if (this.popoverRef) {
      this.popoverRef.hoveredOver = true;
    }
  }

  onMouseLeave() {
    if (this.popoverRef) {
      this.popoverRef.hoveredOver = false;
      this.popoverRef.close();
    }
  }
}
