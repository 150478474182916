<div class="om-task-assignment">
  <div om-layout
       om-layout-gutter
       class="padding-normal"
       om-layout-align="space-between center"
       *ngIf="(todo | ngrxPush) as todo">
    <label om-flex
           om-layout
           om-layout-gutter
           om-layout-align="start center">
      <span om-flex="25">Assigned to</span>

      <omg-auto-complete placeholder="Start typing a person or role"
                         #assigneeSearch
                         (click)="clearAssignee()"
                         (blurAutocomplete)="restoreAssignee(todo)"
                         [items]="searchResults | ngrxPush"
                         trackByKey="id"
                         omgStopEvent="click"
                         bindLabel="label"
                         om-layout-fill
                         (search)="onSearch($event)"
                         [searchFn]="filterTaskAssignees"
                         (changeAutocomplete)="onAssigneeChange(todo)"
                         [(ngModel)]="todo.assignee"
                         [hideDropdownArrow]="true"
                         [highlightMatch]="false"
                         [hideClearAll]="true"
                         [clearable]="true"
                         resetItemsOnClear="true"
                         (selectFocus)="focus(assignedTo)"
                         (focusout)="focusout(assignedTo)"
                         data-cy="task-assignment-assigned-to">
        <ng-template let-item="optionData">
          <span class="caption"
                *ngIf="item.outUntil">
            {{ item['label'] }} - returns {{ item['outUntil'] }}
          </span>
          <span *ngIf="!item.outUntil">
            {{ item['label'] }}
          </span>
        </ng-template>
      </omg-auto-complete>
    </label>
    <omg-checkbox label="High Priority"
                  [(ngModel)]="todo.highPriority"
                  (ngModelChange)="onPriorityChange(todo)"
                  data-cy="task-assignment-high-priority-checkbox"></omg-checkbox>
  </div>
  <ng-content></ng-content>
</div>
