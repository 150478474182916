<div om-layout="vertical"
     om-layout-gutter
     class="pdmp-dialogue-banner padding-normal">

  <div *ngIf="hasParticipatingStates; else noLink">
    <a (click)="onShowFullRxHistoryClick()"
       class="pdmp-report-link om-link -plain-text"
       data-cy="pdmp-show-report-btn">
      {{ viewLinkLabel }}
    </a>
  </div>

  <ng-template #noLink>
    <div class="pdmp-report-nolink">
      {{ viewLinkLabel }}
    </div>
  </ng-template>

  <div *ngIf="viewOutsideLabel">{{ viewOutsideLabel }}</div>
  <omg-checkbox [label]="confirmationLabel"
                plainLabel="true"
                [(ngModel)]="isChecked"
                (ngModelChange)="onConfirmReportViewedChange()"
                class="pdmp-dialogue-banner-confirm">
  </omg-checkbox>
</div>
