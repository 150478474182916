import { Component } from '@angular/core';

@Component({
  selector: 'omg-pdmp-overdose-score-explanation',
  templateUrl: './overdose-score-explanation.component.html',
  styleUrls: ['./overdose-score-explanation.component.scss'],
})
export class PdmpOverdoseScoreExplanationComponent {
  constructor() {}
}
