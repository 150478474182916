import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FrequencyInterval } from '@app/modules/medications/shared/frequency-interval.type';
import {
  DosingUnitOfMeasureDescription,
  MedicationDispensable,
} from '@app/modules/medications/shared/medications.type';
import { AutoCompleteComponent } from '@app/shared';

import { StructuredRegimenForm } from './../../structured-regimen-form';
import { displayedDosingUnitOfMeasure } from './../../utils/dose-utils';

@Component({
  selector: 'omg-structured-regimen-editor',
  templateUrl: './structured-regimen-editor.component.html',
})
export class StructuredRegimenEditorComponent
  implements OnInit, OnDestroy, OnChanges {
  @Output() toggle = new EventEmitter<void>();
  @Output() changeEvent = new EventEmitter<any>();
  @Input() dispensable: MedicationDispensable;
  @Input() frequencyIntervals: FrequencyInterval[];
  @Input() form: StructuredRegimenForm;

  @ViewChild('frequencyIntervalInput', { static: true })
  frequencyIntervalInput: AutoCompleteComponent;
  @ViewChild('durationDaysInput')
  durationDaysInput: ElementRef;

  editingDuration: boolean;
  maxDurationDays: number;
  dosingUnitOfMeasure: string;

  private unsubscribe$ = new Subject<void>();

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    const currentValue = (changes.dispensable &&
      changes.dispensable.currentValue) || { id: null };
    const previousValue = (changes.dispensable &&
      changes.dispensable.previousValue) || { id: null };

    if (currentValue.id !== previousValue.id) {
      this.setDosingUnitOfMeasure();
    }
  }

  ngOnInit() {
    this.form.controls
      .get('dose')
      .valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe(this.setDosingUnitOfMeasure);

    this.editingDuration = !!this.form.controls.get('durationDays').value;
  }

  /* istanbul ignore next */
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  specifyDuration() {
    this.editingDuration = !this.editingDuration;
    const durationEnabled = this.dispensable && this.editingDuration;
    this.form.setDurationValidators(durationEnabled);
    this.form.focusOnDuration();
    this.changeEvent.emit({ type: 'specifyDurationClick' });
  }

  onUseFreeTextClick() {
    this.toggle.emit();
    this.changeEvent.emit({ type: 'useFreeTextClick' });
  }

  onDoseChange(event) {
    this.changeEvent.emit({ event: event, type: 'doseChange' });
  }

  onFrequencyIntervalIdChange(event) {
    this.changeEvent.emit({ event: event, type: 'frequencyIntervalIdChange' });
  }

  onDurationDaysChange(event) {
    this.changeEvent.emit({ event: event, type: 'durationDaysChange' });
  }

  private setDosingUnitOfMeasure = () => {
    const dosingDescription: DosingUnitOfMeasureDescription = this.dispensable
      .dosingUnitOfMeasure;

    this.dosingUnitOfMeasure = displayedDosingUnitOfMeasure(
      this.form.controls.get('dose').value,
      dosingDescription,
    );
  };
}
