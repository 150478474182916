<div class="scores-explanation-popover">
  <div class="header">
    <div class="text">Explanation and Guidelines</div>
  </div>
  <div class="body">
    <div class="text">Narx Scores range from 000-999</div>
    <div class="text">Narx Scores are designed to draw awareness to the presence of significant PMP data.
    </div>
    <strong>
      <div>Considerations:</div>
    </strong>
    <div class="text">1. Narx Scores that raise concern should trigger a discussion, not a decision.</div>
    <div class="text">2. While it is true that at very high scores patients are more likely to exhibit
      some form of misuse,
      a score alone cannot be used to determine appropriateness or misuse.</div>
    <div class="text">3. The Narcotic and Sedative scores overlap in that narcotics contribute to the
      sedative score and
      vice versa. As a result, a patient may have a low narcotic score even though they haven’t been
      prescribed a narcotic.</div>
    <div class="text">4. Overlapping prescriptions (two different prescribers writing the same type of
      medication for use on
      the same day)are heavily weighted in the scoring algorithm.</div>

    <a href="https://secure.prep.pmpgateway.net/narx-content/assets/narxcare2/pdf/explain-these-scores.pdf"
       target="_blank">Learn more</a>
  </div>
</div>
